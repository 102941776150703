import Vue from "vue";
import moment from "moment";

const state = {
    summaryContext: null,
    contextFilters: [],
    ownerFilters: [],
    productFilters: [],
    propertyFilters: [],
    statisticsDataCopy: [],
    statisticsDataProducts: [],
    statisticsLoading: false,
    searchFilter: "",
    singleSite: null,
    filteringEnabled: false
};

const actions = {
    setSummaryContext({commit, state}, context) {
        commit('setSummaryContext', context);
    },
    addPropertyFilter({commit, state}, filter) {
        commit('addPropertyFilter', filter);
    },
    removePropertyFilter({commit, state}, filter) {
        commit('removePropertyFilter', filter);
    },
    setContextFilters({commit, state}, filters) {
        commit('setContextFilters', filters);
    },
    setOwnerFilters({commit, state}, filters) {
        commit('setOwnerFilters', filters);
    },
    setSearchFilter({commit, state}, key) {
        commit('setContextSearchFilter', key);
    },
    setProductFilters({commit, state}, filters) {
        commit('setProductFilters', filters);
    },
    clearAllFilters({commit}) {
        commit('clearAllFilters');
    },
    generateStatisticsData({commit, getters}) {
        commit('copyToStatistics', Vue.prototype.$clone(getters.instancesFiltered));
    },
    setStatisticsLoading({commit}, loading) {
        commit('setStatisticsLoading', loading);
    },
    setFilteringEnabled({commit}, enabled) {
        commit('setFilteringEnabled', enabled);
    },
    reset({commit}) {
        commit('setSummaryContext', null);
    }
};

const mutations = {
    setStatisticsLoading(state, loading) {
        state.statisticsLoading = loading;
    },
    setSummaryContext(state, context) {
        state.summaryContext = context;
    },
    setContextFilters(state, filters) {
        state.contextFilters = filters;
    },
    setOwnerFilters(state, filters) {
        state.ownerFilters = filters;
    },
    setContextSearchFilter(state, key) {
        state.searchFilter = key === null ? '' : key.toLowerCase();
    },
    setFilteringEnabled(state, enabled) {
        state.filteringEnabled = enabled;
    },
    setProductFilters(state, filters) {
        state.productFilters = filters;
    },
    clearAllFilters(state) {
        state.contextFilters = [];
        state.ownerFilters = [];
        state.propertyFilters = [];
        state.searchFilter = '';
    },
    copyToStatistics(state, instances) {
        state.statisticsDataProducts = Vue.prototype.$clone(state.productFilters);
        state.statisticsDataCopy = instances;
    },
    addPropertyFilter(state, {key, val}) {
        if (key === undefined || val === undefined) {
            console.error("Filter " + key + " / " + val + " not valid format!");
            return;
        }
        if (Array.findFirst(state.propertyFilters, f => (f.key === key && (typeof val === 'object' ? f.val === JSON.stringify(val) : f.val === val)))) {
            //console.error("Filter " + key + " / " + val + " already set!");
            return;
        }
        state.propertyFilters.push({key: key, val: (typeof val === 'object' ? JSON.stringify(val) : val)});
    },
    removePropertyFilter(state, {key, val}) {
        const itemToRemove = Array.findFirst(state.propertyFilters, f =>
            (f.key === key && (typeof val === 'object' ? f.val === JSON.stringify(val) : f.val === val))
        );
        if (!itemToRemove) {
            console.error("Remove filter - could not find " + key + " / " + val + "!");
            return;
        }
        Array.remove(state.propertyFilters, itemToRemove);
    }
};

const getters = {
    productInstances: (state, getters, rootState, rootGetters) => {
        const items = [];

        const ownersMap = rootGetters['owners/idMap'];
        const overviewItems = rootGetters['overviewContexts/withStatus'];
        const distributorMap = rootGetters['distributors/idMap'];
        const resellerMap = rootGetters['resellers/idMap'];

        if(!rootGetters['owners/isLoaded']) {
            console.log("Owners not loaded....");
            return [];
        }

        if(!rootGetters['distributors/isLoaded']) {
            console.log("Distributors not loaded....");
            return [];
        }

        if(!rootGetters['resellers/isLoaded']) {
            console.log("Resellers not loaded....");
            return [];
        }

        overviewItems.forEach(item => {
        /* Expand with common values */
            const commonData = {
                resellerName: _.get(item, 'mngByName', 'N/A'),
                distributorName: '',
                chainName: '-',
                brandName: '-',
                nick: '',
                notes: '',
                wlbl: '',
                extId: '',
                pvId: null,
                pvTok: null,
                demo: false,
                watermarked: [],
                contractRefs: [],
                maxClientsLic: 0,
                numberOfClients: 0,
                offlineClients: 0,
                tvTypes: {},
                tvMod: {},
                tvFw: {},
                urlName: "",

                sigFw: {},
                sigMod: {},
                sigClientTypes: {},
                numberOfSigClients: 0,
                offlineSigClients: 0,
                numberOfCasts: 0,
                castOnline: false,
                castVersion: "",
                agentOnline: false,
                agentVersion: "",

                ctry: 'N/A',
                facId: '',
                industry: '',
                mngBy: null,
                mngByName: '',
                clientTypeCount: {},
                productType: '',
                roomCnt: 0,
                occupied: 0,
                onl: true,
                lowerCaseName: item.name ? item.name.toLowerCase() : null
            };

            if(Array.isArray(item.owners)) {
                item.owners.forEach(ownerId => {
                    const owner = ownersMap[ownerId];
                    if (owner) {
                        if (owner.type === 'Brand') {
                            commonData.brandName = owner.name;
                            commonData.brandId = owner.id;
                            if (owner && owner.belongsTo) {
                                const ownersOwner = ownersMap[owner.belongsTo];
                                commonData.chainName = ownersOwner ? ownersOwner.name : owner.belongsTo;
                                commonData.chainId = owner.belongsTo;
                            }
                        } else if (owner.type === 'Chain') {
                            commonData.chainName = owner.name;
                            commonData.chainId = owner.id;
                        }
                    }
                });
            }

            for(const productType in item.products) {
                const newItem = {...(Vue.prototype.$clone(commonData)), ...(Vue.prototype.$clone(item))};
                newItem.productType = productType;
                newItem.contextId = item.id;
                newItem.id = item.id + productType;
                newItem.mapLoc.la = (newItem.mapLoc.la == null || isNaN(newItem.mapLoc.la)) ? 59.9088 : parseFloat(newItem.mapLoc.la);
                newItem.mapLoc.ln = (newItem.mapLoc.ln == null || isNaN(newItem.mapLoc.ln)) ? 10.757824 : parseFloat(newItem.mapLoc.ln);

                if(productType !== 'mirage') {
                    if(item.products[productType].licVal !== true && item.products[productType].licVal !== 'true') {
                        newItem.watermarked =  ["license-invalid"];
                    }
                }

                switch(productType) {
                    case 'mirage': {
                        if(resellerMap.hasOwnProperty(item.mngBy)) {
                            const distributor = resellerMap[item.mngBy].managedBy;
                            if (distributorMap.hasOwnProperty(distributor)) {
                                newItem.distributorName = distributorMap[distributor].name;
                            }
                        }
                        newItem.productName = "DEP";
                        newItem.demo = _.get(newItem, 'cfg.demo', false) === true;
                        const watermark = _.get(newItem, 'cfg.watermark', '');
                        newItem.watermarked = (watermark === undefined || watermark === '') ? [] : [watermark];
                        if(item.proDate != null) {
                            newItem.productionDate = item.proDate;
                        }
                        newItem.createdDate = item.creDate;
                        newItem.facId = item.id;
                    }
                        break;
                    case 'enterprise':
                        newItem.productName = "Enterprise";
                        newItem.createdDate = item.products[productType].actDt;
                        newItem.urlName = item.products[productType].urlName;
                        break;
                    case 'ctrl':
                        newItem.productName = "Enterprise CTRL";
                        newItem.createdDate = item.products[productType].actDt;
                        newItem.urlName = item.products[productType].urlName;
                        break;
                    case 'chromecast':
                        newItem.productName = "Chromecast";
                        newItem.watermarked = [];
                        newItem.urlName = item.products[productType].urlName;
                        newItem.standalone = !item.products.hasOwnProperty('ctrl') && !item.products.hasOwnProperty('enterprise');
                }
                if(newItem.hasOwnProperty('productionDate') && newItem.productionDate !== "") {
                    newItem.productionDateString = moment(newItem.productionDate).format('YYYY-MM-DD');
                } else {
                    newItem.productionDateString = "n/a";
                    newItem.productionDate = "";
                }

                if(newItem.hasOwnProperty('createdDate') && newItem.createdDate !== "") {
                    newItem.creationDateString = moment(newItem.createdDate).format('YYYY-MM-DD');
                } else {
                    newItem.creationDateString = "n/a";
                    newItem.createdDate = "";
                }
                items.push({ ...newItem, ...item.products[productType] });
            }
        });

        return items;
    },
    /* Optimization, make getters that does not use the filtered and processed getter */
    mirageContexts: (state, getters, rootState) => {
        return rootState.overviewContexts.items.filter(s => s.products.hasOwnProperty('mirage'));
    },
    mirageInstances: (state, getters) => {
        return getters.productInstances.filter(s => s.productType === 'mirage');
    },
    enterpriseContexts: (state, getters, rootState) => {
        return rootState.overviewContexts.items.filter(s => s.products.hasOwnProperty('enterprise'));
    },
    ctrlContexts: (state, getters, rootState) => {
        return rootState.overviewContexts.items.filter(s => s.products.hasOwnProperty('ctrl'));
    },
    chromecastContexts: (state, getters, rootState) => {
        return rootState.overviewContexts.items.filter(s => s.products.hasOwnProperty('chromecast'));
    },
    chromecastInstancesFiltered: (state, getters) => {
        return getters.instancesFiltered.filter(s => s.productType === 'chromecast');
    },
    contextFilters: (state, getters, rootState, rootGetters) => {
        const contextFilters = [];

        if (state.contextFilters.length > 0) {
            /* Check for distributors */
            state.contextFilters.forEach((id) => {
                const context = Array.findFirstByField(rootGetters['allContexts'](false), 'id', id);
                if (!context) {
                    console.error("Could not find context with ID " + id);
                    contextFilters.push(id);
                } else {
                    if (context.contextType === 'Distributor') {
                        /* Add all resellers */
                        rootState.resellers.items.forEach(reseller => {
                            if (reseller.managedBy === context.id) {
                                contextFilters.push(reseller.id);
                            }
                        });
                    } else {
                        contextFilters.push(id);
                    }
                }
            });
        }
        return contextFilters;
    },
    /* Filter based on anything but the freetext bit */
    contextsFiltered: (state, getters) => {
        if(!state.filteringEnabled) {
            return [];
        }
        const items = getters.productInstances;
        const contextFilters = getters.contextFilters;
        return items.filter(
            item => {
                if (state.productFilters.length && state.productFilters.indexOf(item.productType) === -1) {
                    return false;
                }

                if(contextFilters.length && contextFilters.indexOf(item.mngBy) === -1) {
                    return false;
                }

                if(state.ownerFilters.length && state.ownerFilters.indexOf(item.brandId) === -1 && state.ownerFilters.indexOf(item.chainId) === -1) {
                    return false;
                }

                for (const filter of state.propertyFilters) {
                    if (!item.hasOwnProperty(filter.key)) {
                        return false;
                    }
                    if(typeof filter.val === 'function') {
                        if (!filter.val(item[filter.key])) {
                            return false;
                        }
                    } else {
                        switch (typeof item[filter.key]) {
                            case 'object': {
                                if (JSON.stringify(item[filter.key]) !== filter.val) {
                                    return false;
                                }
                            }
                                break;
                            default: {
                                if (item[filter.key] !== filter.val) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                return true;
            }
        ).sort((i1, i2) => i1.name.localeCompare(i2.name)); /** Sort here as we assume this is called more seldom */

    },
    instancesFiltered: (state, getters, rootState) => {
        if(!state.filteringEnabled) {
            return [];
        }

        const items = getters.contextsFiltered;
        if (state.searchFilter.length < 3) {
            return items;
        }

        /** Find all active and searchable headers */
        const activeHeaders = {};
        for(const key in rootState.homeSiteHeaders.listHeaders) {
            if(rootState.homeSiteHeaders.listHeaders[key].searchable === true && rootState.homeSiteHeaders.listHeaders[key].enabled) {
                activeHeaders[key] = rootState.homeSiteHeaders.listHeaders[key];
            }
        }

        /** Search trough enabled fields */
        return items.filter(
            item => {
                /** Name */
                if(item.lowerCaseName && item.lowerCaseName.indexOf(state.searchFilter) !== -1) {
                    return true;
                }
                /** Active columns */
                for(const field in activeHeaders) {
                    if(item.hasOwnProperty(field)) {
                        if (typeof activeHeaders[field].convertToString === 'function') {
                            if(activeHeaders[field].convertToString(item).toLowerCase().indexOf(state.searchFilter) !== -1) {
                                return true;
                            }
                        } else {
                            if((typeof item[field] === 'string') && item[field].toLowerCase().indexOf(state.searchFilter) !== -1) {
                                return true;
                            }
                        }
                    } else {
                        console.error("Header " + field + " not found!");
                    }
                }
                return false;
            }
        );
    },
    sitesFilteredIdMap: (state, getters) => {
        return Map.createFromArray(getters.instancesFiltered, 'id');
    },
    statsSitesFilteredManagedByMap: (state) => {
        const managedByMap = {};
        state.statisticsDataCopy.forEach(site => {
            if (!managedByMap.hasOwnProperty(site.mngBy)) {
                managedByMap[site.mngBy] = [];
            }
            managedByMap[site.mngBy].push(site);
        });
        return managedByMap;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
