/* English translations */
export default {
    "generic": {
        "value": "Value",
        "selectValue": "Select value",
        "options": "Options",
        "insert": "Insert",
        "moveEntryUp": "Move entry up",
        "moveEntryDown": "Move entry down",
        "set": "Set",
        "onboard": "Onboard",
        "view": "View",
        "open": "Open",
        "days": "days",
        "minutes": "minutes",
        "log": "Log",
        "event": "Event",
        "eventReason": "Reason",
        "occuredAt": "Occured at",
        "lastKeepalive": "Last keepalive",
        "allocated": "Allocated",
        "active": "Active",
        "inactive": "Inactive",
        "firmware": "Firmware",
        "from": "From",
        "to": "To",
        "ipValidationErrorMsg": "Valid IP Address required",
        "networks": "Networks",
        "ipAddress": "IP Address",
        "host": "Host",
        "total": "Total",
        "add": "Add",
        "all": "All",
        "addWithType": "Add {type} | Add {type}s",
        "agent": "Agent | Agents",
        "signageScreen": "Signage Screen",
        "agentStatus": "Agent status",
        "delete": "Delete",
        "deleteAll": "Delete all",
        "deleteWithType": "Delete {type} | Delete {amount} {type}s",
        "deleteConfirm": "Are you sure you wish to delete this {type}? | Are you sure you wish to delete these {amount} {type}s?",
        "deleteConfirmWithIdentifier": "Are you sure you wish to delete {type} {id}?",
        "deleted": "{type} successfully deleted | {type}s successfully deleted",
        "deleteFailed": "Failed to delete {type} | Failed to delete {type}s ",
        "deletedWithIdentifier": "{id} successfully deleted",
        "deletedWithTypeAndIdentifier": "{type} {id} deleted",
        "deleteUserConfirmation": "I confirm that I want to delete this {type}. I acknowledge that this operation is final and the {type} can not be recovered.|I confirm that I wish to delete these {amount} {type}s. I acknowledge that this operation is final and the {type}s can not be recovered.",
        "fetchFailed": "Error fetching {itemName}",
        "imported": "{cnt} {type} successfully imported | {cnt} {type}s successfully imported",
        "saved": "{type} successfully saved | {type}s successfully saved",
        "updated": "{type} successfully updated | {type}s successfully updated",
        "created": "{type} successfully created | {type}s successfully created",
        "selectType": "Select {type}",
        "manageType": "Manage {type}",
        "itemReverted": "Item reverted|Items reverted",
        "valueDuplicate": "{value} already exists",
        "remove": "Remove",
        "disable": "Disable",
        "disabled": "Disabled",
        "download": "Download",
        "enable": "Enable",
        "enabled": "Enabled",
        "systemDefault": "System default",
        "removeWithType": "Remove {type} | Remove {type}s",
        "removeConfirm": "Are you sure you wish to remove this {type}? | Are you sure you wish to remove these {type}s?",
        "removeConfirmWithTarget": "Are you sure you wish to remove {type} from this {target}? | Are you sure you wish to remove these {type}s from this {target}?",
        "group": "Group | Groups",
        "rename": "Rename",
        "renameWithType": "Rename {type}",
        "renamedTo": "{type} renamed to {name}",
        "create": "Create",
        "createToolTip": "Create without closing",
        "createAndClose": "Create & close",
        "createWithType": "Create {type}",
        "createNew": "Create new",
        "createNewWithType": "Create new {type} | Create new {type}s",
        "takeEffectImmediatly": "This will take effect immediately",
        "newWithType": "New {type} | New {type}s",
        "actions": "Actions",
        "yes": "Yes",
        "no": "No",
        "none": "None",
        "move": "Move",
        "loading": "Loading",
        "unknown": "Unknown",
        "typeToSearch": "Start typing to search",
        "enterSearchTerm": "Enter search term",
        "noSearchMatches": "No search matches",
        "cancel": "Cancel",
        "close": "Close",
        "gotIt": "Got it",
        "required": "Required",
        "numberRequired": "Number required",
        "fieldRequired": "Field required",
        "inputRequired": "Input required",
        "integration": "Integration | Integrations",
        "selectionRequired": "Selection required",
        "nameRequired": "Name required",
        "inputLongerThan": "Input must be longer than {length} character | Input must be longer than {length} characters",
        "countLessThan": "Count must be less than {length}",
        "inputShorterThan": "Input must be shorter than {length} characters",
        "nameInvalidFormat": "Name should only contain letters, numbers and spaces",
        "madeYet": "No {type} have been made yet | No {type}s have been made yet",
        "noMoreItemsAvailable": "No more {type} available | No more {type}s available",
        "searchCriteria": "No {type}s matching the given search criteria",
        "search": "Search",
        "save": "Save",
        "saveAndClose": "Save & Close",
        "saveWithType": "Save {type} | Save {type}s",
        "editItem": "Edit item | Edit items",
        "edit": "Edit | Edits",
        "editWithType": "Edit {type} | Edit {type}s",
        "previewMode": "Preview",
        "shareName": "Share {type}",
        "action": "Action | Actions",
        "refresh": "Refresh",
        "reset": "Reset",
        "resetRefresh": "Reset and refresh {type}",
        "lostChanges": "All changes will be lost",
        "noAvailable": "No available {type}s",
        "channelTable": "Channel table",
        "done": "Done",
        "name": "Name",
        "nickname": "Nickname",
        "externalId": "External Id",
        "crmRef": "CRM Ref",
        "industry": "Industry",
        "filename": "Original filename",
        "content": "Content",
        "fullscreen": "Fullscreen",
        "exitFullscreen": "Exit fullscreen",
        "copy": "Copy",
        "copyToClipboard": "Copy to clipboard",
        "copiedToClipboard": "Content copied to clipboard",
        "copyToSite": "Copy to site",
        "preview": "Preview",
        "reload": "Reload",
        "unsaved": "Unsaved changes will be lost",
        "type": "Type | Types",
        "text": "Text",
        "tag": "Tag | Tags",
        "item": "Item | Items",
        "title": "Title",
        "fullTitle": "Full title",
        "editor": "Editor",
        "number": "Number | Numbers",
        "top": "Top",
        "left": "Left",
        "width": "Width",
        "height": "Height",
        "zIndex": "z-index",
        "field": "Field | Fields",
        "key": "Key | Keys",
        "description": "Description",
        "setup": "Setup",
        "status": "Status | Status",
        "multiple": "Multiple",
        "default": "Default",
        "general": "General",
        "configuration": "Configuration",
        "config": "Config",
        "select": "Select",
        "addAll": "Add all",
        "removeAll": "Remove all",
        "previous": "Previous",
        "next": "Next",
        "finish": "Finish",
        "nameLonger": "Name must be longer than {length} characters",
        "selectAll": "Select all",
        "deselectAll": "Deselect all",
        "code": "Code",
        "continue": "Continue",
        "stay": "Stay",
        "back": "Back",
        "language": "Language",
        "languageName": "Language name",
        "languageCode": "Language code",
        "reseller": "Reseller | Resellers",
        "resource": "Resource | Resources",
        "override": "Override | Overrides",
        "insufficientPrivileges": "Account does not have sufficient privileges",
        "theme": "Theme | Themes",
        "distributor": "Distributor | Distributors",
        "domain": "Domain | Domains",
        "preregister": "Pre-register",
        "pleaseConfirm": "Please confirm",
        "switchTo": "Switch to...",
        "home": "Home",
        "lastLoggedIn": "Last logged in",
        "summary": "Summary",
        "noData": "No data to display...",
        "vendor": "Vendor | Vendors",
        "model": "Model | Models",
        "user": "User",
        "schedule": "Schedule | Schedules",
        "revert": "Revert",
        "revertAllChanges": "Revert all changes since last save?",
        "revertNoChanges": "No changes to revert",
        "channels": "Channel | Channels",
        "deployment": "Deployment | Deployments",
        "filter": "Filter",
        "accept": "Accept",
        "api": "API",
        "timeZone": "Time zone | Time zones",
        "register": "Register",
        "caseSensitive": "Case sensitive",
        "optional": "Optional",
        "errorOccurred": "An error occurred",
        "on": "On",
        "off": "Off",
        "standby": "Standby",
        "idle": "Idle",
        "notSet": "Not set",
        "exportCSV": "Export CSV",
        "importCSV": "Import CSV",
        "success": "Success",
        "uploaded": "Uploaded",
        "hasUnsavedChangesHeader": "Unsaved changes",
        "hasUnsavedChangesMessage": "Changes you have made may not be saved.",
        "defaultErrorMsg": "Default Error Message",
        "notes": "Notes",
        "genericNotes": "Generic notes",
        "internalNotes": "Internal notes",
        "whitelabelDomain": "Whitelabel domain",
        "createNewSchedule": "Create new schedule",
        "addToSchedule": "Add to existing schedule",
        "readOnly": "Read only",
        "information": "information",
        "custom": "Custom",
        "url": "URL",
        "realm": "Keycloak realm",
        "noResult": "No result | No results",
        "sharingContext": "Resource Provider | Resource Providers",
        "contentProviderContext": "Content Provider | Content Providers",
        "sharingSuccess": "Sharing updated",
        "sharingError": "Unable to update sharing",
        "lastPublished": "Last published",
        "notPublished": "{0} is currently not published",
        "itemType": "{0}",
        "shareAll": "All",
        "shareSubGroup": "Sub-group",
        "shareSingle": "Single",
        "debug": "Debug"
    },
    "validation": {
        "characterMaxLength": "Cannot be more than {value} characters",
        "valueHigherThan": "Value must be higher than {value}",
        "valueHigherThanOrEqualTo": "Value must be higher or equal to {value}",
        "valueLowerOrEqualTo": "Value must be lower or equal to {value}",
        "powerOnOverrideFrom": "Must be before 'Override to'",
        "powerOnOverrideTo": "Must be after 'Override from",
        "frequency": "Must be valid frequency",
        "ipPort": "Valid port required",
        "invalidContextType": "Context type already exists, context type must be unique",
        "minTimeDiffInMinutes": "There must be at least {value} minutes between the start and end time",
        "minTimeDiffFromPreviousNextDay": "The time difference between the previous day and the next day must be at least {value} minutes",
    },
    "help": {
        "getSupportTitle": "Contact Support",
        "getSiteInformationTitle": "Site Information",
        "notAvailable": "Not available",
        "openDocumentation": "Open documentation",
        "getSupportBody": "<span style=\"font-weight: bold;\">E-mail:</span> {email}<br><span style=\"font-weight: bold;\">Phone:</span> {phone}<br><br>{text}",
        "notAvailableInHomeScreen": "Support information is not available in the home screen.<br>Please switch to a site context.",
        "documentation": "Documentation",
        "contactSupport": "Contact support",
        "mobileNavigationTip": "Tap on the upper left button to open the navigation drawer"
    },
    "eula": {
        "updated": {
            "title": "EULA Updated",
            "text": "<span style='font-size: 16px;font-style: italic;'>We have updated our End User License Agreement, please read through it and accept to continue</span> <br><br>"

        }
    },
    "welcome": {
        "title": "Welcome {user}",
        "languageHeader": "Language",
        "languageText": "Welcome to the Cloud Management Portal.<br><br>This is the main hub of digital surface device and content management, the access point for distributors, resellers, site owners and site users.<br><br>Start out by selecting your preferred language:",
        "homeScreenText": "Depending on your account, you will be logged in to a \"Site Dashboard\" or a \"Portal Dashboard\" and this will be your future home screen.<br><br><div style=\"font-weight: bold;\">Site Dashboard</div>For users with standard single-site access, you will be taken directly to your own \"Site Dashboard\" when logging in to the system.<br>At this starting point you have an overview of the installation screens, stats and administrative info.\nThe specific menu items, resources and options available to you may vary depending on your account type, active licenses and access level granted.<br><br><div style=\"font-weight: bold;\">Portal Dashboard</div>For resellers, site owners and site users with access to more than one site, the \"Portal Dashboard\" is the initial starting point on the platform.<br>Here you manage resources and access combined statistics for all your sites, create users, set up new installations, check licensing details and many other administrative tasks.<br>The specific menu items and options on this administrative area of the platform will vary depending on your account type, active licences and access level granted.<br><br>You can always reach the \"Portal Dashboard\" by selecting it in from top-left context menu from any page. From the same top-left context menu you can also access any and all of your sites to work on each individual installation available to your account.",
        "siteText": "If you are a single-site user you will automatically be logged in to your site once you log into the system. For users with multi-site access you select the site-context in the top-left context menu.<br>The access level you have will determine what resources that are available to you in the site context."
    },
    "language": {
        "english": "English",
        "norwegian": "Norwegian",
        "swedish": "Swedish",
        "finnish": "Finnish",
        "russian": "Russian",
        "italian": "Italian",
        "french": "French",
        "german": "German",
        "spanish": "Spanish",
        "estonian": "Estonian",
        "slovak": "Slovak",
        "greek": "Greek",
        "atLeastOne": "At least one language must be filled in"
    },
    "date": {
        "today": "Today",
        "yesterday": "Yesterday",
        "daysAgo": "{days} days ago",
        "dateRequired": "Date required",
        "dateNotSet": "Not set",
        "timeRequired": "Time required",
        "timeFormat": "Time must be in format hh:mm",
        "amPmTimeFormat": "Time must be in format h:mm AM/PM",
        "daily": "Daily",
        "weekly": "Weekly",
        "time": "Time",
        "date": "Date",
        "timeOfDay": "Time of day",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "startTime": "Start time",
        "endTime": "End time",
        "weekday": "Weekday",
        "endTimeAfter": "The end time must come after the start time",
        "duration": "Duration",
        "inSeconds": "in seconds"
    },
    "router": {
        "agents": "Agents",
        "contentConfiguration": "Configuration",
        "documents": "Documents",
        "resource": "Resource",
        "resources": "Resources",
        "hospitality": "Hospitality",
        "dashboard": "Dashboard",
        "media": "Media",
        "housekeeping": "Housekeeping",
        "screens": "Screens",
        "tv": "TV",
        "playlists": "Playlists",
        "signage": "Signage",
        "sites": "Sites",
        "customersAndLicenses": "Customers & Licenses",
        "sitesAndLicenses": "Sites & Licenses",
        "externalSystems": "External Systems",
        "reseller": "Reseller",
        "resellers": "Resellers",
        "manageResellers": "Manage resellers",
        "distributor": "Distributor",
        "distributors": "Distributors",
        "domain": "Domain",
        "domains": "Domains",
        "usageReport": "Usage report",
        "accessReport": "Access report",
        "resellerUsersAndAccess": "Users & Access",
        "distributorUsersAndAccess": "Users & Access",
        "resellerThemes": "Themes",
        "distributorThemes": "Themes",
        "content": "Content",
        "admin": "Admin",
        "administration": "Administration",
        "system": "System",
        "rooms": "Rooms",
        "virtualRemoteClients": "VRC Sessions",
        "housekeepingRooms": "Rooms",
        "locations": "Locations",
        "locationSetup": "Location setup",
        "guests": "Guests",
        "guestMessages": "Messages",
        "booking": "Booking",
        "wakeups": "Wakeups",
        "config": "Config",
        "screensConfig": "Config",
        "housekeepingConfig": "Config",
        "streamAllocatorConfig": "Config",
        "configuration": "Configuration",
        "issues": "Issues",
        "scheduledTasks": "Scheduled tasks",
        "screen": "Screen",
        "screenConfigurations": "Configurations",
        "tvChannels": "TV Channels",
        "tvScreens": "TV Screens",
        "tvScreensTheme": "TV Screens",
        "signageScreens": "Signage Screens",
        "signageOverview": "Overview",
        "signageSchedules": "Schedules",
        "signageConfig": "Config",
        "event": "Event",
        "events": "Events",
        "wayfinding": "Directions",
        "wayfindingIcons": "Directions",
        "logos": "Logos",
        "integrationLocations": "Integrations",
        "eventConfig": "Config",
        "schedules": "Schedules",
        "schedulemapping": "Schedule mapping",
        "installCodes": "Install codes",
        "interactivePages": "Interactive Pages",
        "templatePages": "Template Pages",
        "channelTables": "Channel tables",
        "mediaChannels": "Media channels",
        "templates": "API Templates",
        "apiTemplates": "API Templates",
        "pages": "Pages",
        "imageresources": "Images",
        "videoresources": "Videos",
        "fontresources": "Fonts",
        "contexts": "Contexts",
        "sharingContexts": "Resource Providers",
        "contentProviderContexts": "Content Providers",
        "accounts": "Accounts",
        "access": "Access",
        "themeImport": "Themes",
        "themes": "Themes",
        "usersAndAccess": "Users & Access",
        "userAccounts": "User Accounts",
        "apiAccounts": "API Accounts",
        "owners": "Owners",
        "areas": "Areas",
        "licenses": "Licenses",
        "products": "Products",
        "roles": "Roles",
        "roleManagement": "Roles",
        "privilegeSets": "Privilege Sets",
        "requiredPermissions": "You do not have the required permissions for this view",
        "systemConfig": "System Config",
        "tvSearch": "TV Search",
        "pmsConfiguration": "PMS Configuration",
        "pmsLogEntries": "PMS Log Entries",
        "resourceSharing": "Share",
        "resourcePublishing": "Publish",
        "publishAndShare": "Publish & Share",
        "themePreview": "Preview",
        "watermark": "Watermark",
        "castManagement": "Cast Management",
        "chromecasts": "Chromecasts",
        "chromecastConfig": "Chromecast Configuration",
        "unregisteredCasts": "Unregistered Casts",
        "activeCastSessions": "Active Sessions",
        "castConfiguration": "Configuration",
        "castStatus": "Status",
        "castStatistics": "Statistics",
        "castEventLogs": "Event Log",
        "streamAllocator": "Stream Allocator",
        "streamManagement": "Streams",
        "streamAllocatorLog": "Stream Allocator Log",
        "deletedContexts": "Deleted Contexts",
        "duplicateRegisteredClients": "Duplicate registered clients",
        "airlineLogos": "Airline Logos",
        "systemAuditLogs": "Audit Logs",
        "deploymentAuditLogs": "Audit Logs",
        "auditLogConfig": "Audit Logs Config",
        "resourceCopy": "Resource Copy",
        "deploymentContentProviderProperties": "Content Provider Properties"
    },
    "appbar": {
        "helpAndSupport": "Help & Support",
        "notifications": "Notifications",
        "languages": "Language selection",
        "settings": "Settings & Utilities",
        "contextSelector": "Select site",
        "availableSites": "Available sites",
        "availableCustomers": "Available customers",
        "availableThemes": "Available themes",
        "searchSites": "Search sites",
        "searchCustomers": "Search customers",
        "searchThemes": "Search themes",
        "availableSharingContexts": "Available resource providers",
        "availableContentProvidersContexts": "Available content providers",
        "searchSharingContexts": "Search resource providers",
        "searchContentProviderContexts": "Search content providers"
    },
    "admin": {
        "admin": "Admin",
        "account": "Account | Accounts",
        "accountRemoved": "Account successfully removed",
        "accountRemoveFailed": "Account was not removed",
        "accountCreated": "New account added",
        "accountSaved": "Access data saved",
        "accountType": "Account Type",
        "accountEnabled": "Account enabled",
        "accountAccess": "Account access",
        "apiAccount": "API account | API accounts",
        "deleteAccount": "Delete account",
        "removeAccount": "Remove account",
        "removeAccessRights": "Remove access rights",
        "removeAccessRightsInfo": "Remove access rights for this account",
        "disableAPIAccount": "Disable API account",
        "enableAPIAccount": "Enable API account",
        "addApiInfo": "The new API account will automatically be enabled for access",
        "accountApiState": "This API account is currently {state}",
        "accountApiStateWithContextId": "This API account is currently {state} for contextId {contextId}",
        "disableApiAccountTitle": "Disable API account?",
        "disableApiAccountWarning": "Disabling an API account causes the access token to be permanently deleted from the system. You can re-enable the account, but this will generate a new token.",
        "disableApiAccountConfirm": "Please confirm that you wish to permanently delete the access token.",
        "deleteUserAccountConfirm": "Please confirm that you wish to remove this account from this",
        "deleteUserAccountTitle": "Remove user account?",
        "deleteUserAccountSuccess": "User account removed",
        "deleteApiAccountSuccess": "API account deleted",
        "deleteAccountGenericFailed": "Could not delete entry!",
        "removedUserAccessSuccess": "User access successfully removed",
        "removedUserAccessFailed": "Failed to remove user access",
        "saveAccountFailed": "Could not save entry!",
        "accountDataSaved": "Account data saved",
        "apiTokenWarning": "You will only see this token this once! Copy this token:",
        "apiTokenTitle": "API Access Token",
        "customer": "Customer | Customers",
        "role": "Role | Roles",
        "privilegeSet": "Privilege Set | Privilege Sets",
        "accountsWithRole": "Accounts assigned '{role}'",
        "rolesWithProduct": "Roles with '{product}'",
        "site": "Site | Sites",
        "system": "System | Systems",
        "country": "Country | Countries",
        "area": "Area | Areas",
        "brand": "Brand | Brands",
        "screensLimit": "Screens limit",
        "licensed": "Licensed",
        "sitesLimit": "Sites Limit",
        "deleteCustomer": "Delete customer",
        "deleteSite": "Delete site",
        "deleteLegacyWarningText": "WARNING: Deleting an external system reference is a non-recoverable action.<br>This action will not have any effect on the external system, but the reference will be removed and your account can not longer see status-data from this system.<br>NONE OF THESE ITEMS CAN BE RECOVERED.",
        "deleteSiteWarningText": "WARNING: Deleting a site is a non-recoverable action.<br>Deleting a site will also delete all related data including: screens, menu-pages, images/fonts, templates, documents, rooms, licenses and more.<br>NONE OF THESE ITEMS CAN BE RECOVERED.",
        "deleteSiteWarningTextPPDS": "WARNING: Deleting a customer is a non-recoverable action.<br>Deleting a customer will also delete all related data including: screens, templates, images/fonts, schedules and more.<br>NONE OF THESE ITEMS CAN BE RECOVERED.",
        "deleteSiteConfirmationLabel": "Enter \"{title}\" into this text field to enable deletion",
        "copyResources": "Copy resources",
        "copyResourcesToSite": "Copy resources to {title}",
        "copyResourcesFromTo": "Copy all resources from {fromContextType} '{from}' to '{to}'?",
        "importResources": "Import resources",
        "importResourcesToSite": "Import resources to {title}",
        "moveSiteFromTo": "Move site from reseller {from} to reseller {to}?",
        "moveSite": "Move site {title}",
        "provisioned": "Provisioned",
        "maxSizeProvisioned": "Max sites provisioned",
        "addReseller": "Add reseller",
        "context": "Context | Contexts",
        "contextType": "Context type | Context types",
        "systemRole": "System role | System roles",
        "systemProduct" : "System product | System products",
        "owner": "Owner | Owners",
        "license": "Licenses | License | Licenses",
        "totalLicenses": "Total licenses",
        "feature": "Feature | Features",
        "changeSite": "Change site",
        "product": "Product | Products",
        "itemId": "Item ID",
        "customId": "Custom ID",
        "access": "Access | Accesses",
        "reseller": "Resellers | Reseller | Resellers",
        "noResellerAccess": "You do not have access to any resellers",
        "noDistributorAccess": "You do not have access to any distributors",
        "distributor": "Distributors | Distributor | Distributors",
        "domain": "Domains | Domain | Domains",
        "resource": "Resource | Resources",
        "accountName": "Account name",
        "displayName": "Display name",
        "email": "Email",
        "phone": "Phone",
        "formDisabledSSO": "User Account settings has been disabled because your current session is logged in through external single sign-on provider",
        "created": "Created",
        "password": "Password",
        "repeatPassword": "Repeat password",
        "passwordHint": "Password must contain at least 8 characters, a combination of lower-/uppercase, numeric digits and special characters",
        "addNewAccess": "Add new access",
        "newAccount": "New user account",
        "addAccount": "Add {type} account",
        "newApiAccount": "New API account",
        "editApiAccount": "Edit API account",
        "accessFetchFailed": "Account access fetch failed",
        "accessUpdateFailed": "Setting account access failed",
        "unknownAccess": "Unknown access",
        "accessToFeatures": "Access to {features} feature | Access to {features} features",
        "passwordLength": "Password must be 8 characters long",
        "passwordMatch": "Passwords must match",
        "contextAccess": "Context access",
        "noFeaturesAccess": "No features have been added to this access",
        "removeAccess": "Remove access",
        "home": "Home",
        "read": "Read",
        "create": "Create",
        "update": "Update",
        "delete": "Delete",
        "oneAccessRelation": "At least one access relation is required",
        "editLicense": "Edit license",
        "contractRef": "Contract ref",
        "activationDate": "Activation date",
        "expirationDate": "Expiration date",
        "noFeaturesSelected": "No features have been selected yet",
        "availableFeatures": "Available features",
        "expirationAfter": "Expiration date must come after activation date",
        "newLicense": "New license",
        "userAccount": "User account",
        "userAdminError": "User admin right could not be added",
        "createUserAccount": "Create a new user account",
        "noAvailableUsers": "No available user accounts",
        "selectUser": "Select a user account",
        "selectUserHelp": "Select a user here if you'd like to give them admin access to the selected context and this new license's features.<br><br>If this context is being handed over to a partner to maintain and set up you can create a new admin user here.<br><br>You can select you own user as well, if you'd like to have full admin rights to this new context.<br><br>Or you can leave the field empty to skip this step entirely.",
        "createNewContext": "Create a new context",
        "noAvailableContexts": "No available contexts",
        "selectContext": "Select a context",
        "selectContextHelp": "A context can be an instance of Enterprise CTRL or a workspace for a resource manager, and is required for a license.<br><br>Please select or create one now.",
        "createNewSite": "Create a new site",
        "noAvailableSites": "No available sites",
        "selectSite": "Select a site",
        "selectSiteHelp": "A site is a physical location in which a context exists - a hotel for example.<br><br>In this step you can select or create a site to use for this new license.",
        "deploymentInfo": "A standard enterprise deployment with a belonging site",
        "managedBy": "Managed by",
        "deployedAt": "Deployed at",
        "locatedIn": "Located in",
        "belongsTo": "Belongs to",
        "streetAddress": "Street address",
        "postalCode": "Postal code",
        "contactName": "Contact name",
        "contactEmail": "Contact email",
        "contactPhone": "Contact phone",
        "contactInfo": "Contact info",
        "defaultLanguage": "Default language",
        "availableLanguages": "Available languages",
        "createAccount": "Create account",
        "createArea": "Create area",
        "createLicense": "Create license",
        "createDistributor": "Create distributor",
        "createDomain": "Create domain",
        "createReseller": "Create reseller",
        "lastChange": "Last change",
        "createOwner": "Create owner",
        "createProduct": "Create product",
        "createSite": "Create site",
        "createCustomer": "Create customer",
        "createResource": "Create resource",
        "createTheme": "Create theme",
        "createSharingContext": "Create Resource Provider",
        "createContentProviderContext": "Create Content Provider",
        "createRole": "Create role",
        "addProperties": "Add properties",
        "property": "Property | Properties",
        "newProperty": "New property",
        "roleName": "Role name",
        "newRole": "New role",
        "newPrivilegeSet": "New privilege set",
        "newDistributor": "New distributor",
        "newDomain": "New domain",
        "selectRole": "Select roles",
        "noRolesAccess": "No roles have been added to this access",
        "removeRole": "Remove",
        "accessToRoles": "{roles} role | {roles} roles",
        "availableRole": "Available role | Available roles",
        "limits": "Limit | Limits",
        "oldPassword": "Old password",
        "newPassword": "New password",
        "repeatNewPassword": "Repeat new password",
        "vendorConfig": "Vendor configuration",
        "notificationRecipients":"Notification",
        "notificationGroup": "Notification Group",
        "notificationRecipientsTitle": "Notification recipients for {name}",
        "notificationRecipientsInfo": "These recipients will be notified about license and site changes",
        "emailAlreadyExists": "Email already exists",
        "usingCustomSupportInfo": "Custom support info",
        "myAccount": "My account",
        "userSaveFailedGeneral": "Name should only contain letters, numbers and single space",
        "userSaveFailedEmail": "Could not update user email address",
        "userChangeEmailHeader": "To change your e-mail address you must provide your password",
        "updateEmail": "Update email",
        "userSaveFailedPassword": "Could not update user password",
        "userUpdatePasswordTitle": "Update password",
        "userUpdatePasswordDescription": "If successful, this will log out all active sessions for your current user. Proceed?",
        "changeYourPassword": "Change your password",
        "changePassword": "Change password",
        "share": "Share",
        "addNewShare": "Share with...",
        "addApiAccount": "Add API account",
        "addUserAccount": "Add user account",
        "resellerLicenseInfoSite": "Resellers can provision sites and licenses. You are managing",
        "resellerLicenseInfoCustomer": "Resellers can provision customers and licenses. You are managing",
        "distributorLicenseInfo": "Distributors can provision resellers. You are managing",
        "newReseller": "New reseller",
        "editAccessFor": "Edit Access for",
        "editAccessToReseller": "Edit Access to reseller",
        "distributorAccess": "Distributor access",
        "domainAccess": "Domain access",
        "sharingAccess": "Sharing access",
        "contentProviderAccess": "Content provider access",
        "resellerAccess": "Reseller access",
        "themeAccess": "Theme access",
        "siteAccess": "Site access",
        "deleteDistributorText": "A distributor can only be deleted if it has no resellers and no themes provisioned.<br>Please confirm that you wish to delete this distributor?",
        "deleteDistributorFailedText": "Could not delete distributor: {details}",
        "distributorHasProvisionedContext": "there are provisioned resellers and/or themes tied to the distributor",
        "deleteDomainText": "A domain can only be deleted if it has no distributors and no themes provisioned.<br>Please confirm that you wish to delete this domain?",
        "deleteDomainFailedText": "Could not delete domain: {details}",
        "domainHasProvisionedContext": "there are provisioned distributors and/or themes tied to the domain",
        "externalSystemsHeading": "External system information can be edited here",
        "createPrivilegeSet": "Create privilege set",
        "privilegeSetName": "Privilege set name",
        "systemPrivilegeSet": "System privilege set",
        "noPrivilegeSetsAdded": "No privilege sets has been added",
        "chooseTypePrivilegeSet": "Choose a 'Context type' before selecting Privilege Sets",
        "forceSSO": "Force SSO",
        "forceSSOHint": "Use this if you wish to limit user access to a specific 3rd party SSO provider",
        "idp": "Force SSO",
        "copyRole": "Copy from existing role",
        "roleProductsHint": "This role will only be available for the selected products",
        "roleProductDisabledHint": "Only applies to non-system deployment roles",
        "copyOfName": "Copy of {name}",
        "contextPrivilegeSetSelectionTitle": "Access selection",
        "contextPrivilegeSetPreviewTitle": "Access preview",
        "contextPrivilegeSelectionDescription": "Choose which portal menus this role will have access to",
        "contextPrivilegePreviewDescription": "Preview of portal menus this role have access to",
        "contextPrivilegePreviewWarning": "System roles cannot be edited",
        "deletedContext": "Deleted Context",
        "finalizeDelete": "Finalize Deletion",
        "undeleteConfirmMsg": "Are you certain you wish to undelete this context? | Are you certain you wish to undelete these {amount} contexts?",
        "undelete": "Undelete",
        "undeleteSuccess": "Context undeleted | {amount} contexts undeleted",
        "undeleteFailed": "Undelete failed",
        "finalizeDeleteConfirmMsg": "Are you sure you wish to finalize delete this context? | Are you sure you wish to finalize deletion of these {amount} contexts?",
        "finalizeDeleteSuccess": "Context deletion finalized | {amount} contexts deletion finalized",
        "finalizeDeleteFailed": "Finalize delete failed",
        "activateSite": "Activate Site",
        "activateSiteDescription": "Activating a site will remove all watermark and start the billing plan",
        "activateSiteConfirmTex": "I understand that this action is final",
        "siteActivated": "Site activated",
        "duplicateRegisteredClients": "Duplicate registered client",
        "airlineName": "Airline Name",
        "airlineIataCode": "IATA Airline Code",
        "airlineLogo": "Airline Logo | Airline Logos",
        "newAirlineLogo": "New Airline Logo",
        "duplicateIATAError": "Logo for IATA '{airlineCode}' already exists",
        "incorrectAirlineLogoDimensions": "Airline Logo file dimensions must be 300 width by 100 height.",
        "incorrectAirlineLogoFileType": "Airline Logo file must be of an image of type png.",
        "airlineLogoSaveFailed": "Failed to save airline logo",
        "auditLogs": "Audit Logs",
        "auditLogBlacklist": "Blacklist",
        "userId": "User ID",
        "copiedAuthToken": "Copied auth token",
    },
    "hospitality": {
        "hospitality": "Hospitality",
        "room": "Room | Rooms",
        "additionalRooms": "Additional Rooms",
        "occupancy": "Occupancy",
        "guest": "Guest | Guests",
        "wakeup": "Wakeup | Wakeups",
        "guestMessage": "Messages | Message | Messages",
        "stay": "Stay | Stays",
        "account": "Account | Accounts",
        "virtualRemoteClients": {
            "session": "session | sessions",
            "roomName": "Room",
            "screenName": "TV",
            "sessionId": "Session ID",
            "remoteClientId" : "VRC ID",
            "created": "Created at",
            "tvOnline": "TV Online",
            "vrcOnline": "VRC Online",
        },
        "config": {
            "chromecastCheckoutLabel": "Chromecast Checkout",
            "chromecastCheckoutExplanation": "Reset Chromecasts associated with room upon guest checkout.",
            "chromecastFacilityIdLabel": "Chromecast Facility ID",
            "chromecastTokenLabel": "Chromecast Token",
            "serviceMenuCodeLabel": "Service Menu Code",
            "nightTimeToggleLabel": "Night Time",
            "nightTimeInfo": "Period where TVs will not power on for any events except wakeups.",
            "nightTimeFromLabel": "Night Time From",
            "nightTimeToLabel": "Night time To",
            "enableDailyCheckinCheckout": "Enable daily check-in/check-out",
            "dailyCheckinCheckoutExplanation": "Warning: Only applicable if PMS vendor is NONE"
        }
    },
    "dashboard": {
        "welcome": "Welcome {name}",
        "signedInto": "You are currently signed into site {site}",
        "occupancyLastNextWeek": "Occupancy last & next week",
        "upcomingWakeups": "Upcoming wakeups",
        "mediaChannels": "Media channels defined",
        "activeInstallCodes": "Active install codes!",
        "newIssuesLastDays": "New issues last {days} days",
        "newLastDays": "New in the last {days} days",
        "account": "User account | User accounts",
        "apiAccount": "API account | API accounts",
        "occupancyPercent": "Occupancy (%)",
        "sectionHelp": "Close and open dashboard sections by clicking on their respective headers",
        "filterSites": "Filter sites by",
        "filterCustomers": "Filter customers by",
        "clickOnMap": "Click on item in map for details",
        "displayingData": "Displaying data for {amount} site | Displaying data for {amount} sites",
        "noFeaturesActivated": "No features activated...",
        "clearSelection": "Clear selection",
        "details": "Details",
        "openExternal": "Open mgmnt portal",
        "openSite": "Open site",
        "openSiteError": "Cannot open site of type {type}",
        "openTvPreview": "Open TV Preview",
        "notManaged": "Not managed",
        "vacant": "Vacant",
        "managementAccess": "Management access",
        "mergeCCs": "Merge duped Casts",
        "mapView": "Map View",
        "listView": "List View",
        "stats": "Stats",
        "map": "Map",
        "list": "List",
        "noReportGenerated": "No report generated",
        "buildReport": "Build Report",
        "rebuildReport": "Rebuild report",
        "generateStatisticsReport": "Generate statistics report",
        "processingData": "Processing data...",
        "configureFields": "Configure fields",
        "downloadSelected": "Download selected",
        "searchAllFields": "Search all visible fields",
        "cluster": "Cluster",
        "installedTvs": "Installed TVs",
        "product": "PRODUCT",
        "filter": "FILTER",
        "hideDemos": "Hide Demos",
        "hideWhitelabeled": "Hide Whitelabeled",
        "hideWatermarked": "Hide Watermarked",
        "licenseInfo": "License Info",
        "tvLicenses": "TV Licenses",
        "signageLicenses": "Signage Licenses",
        "castLicenses": "Cast Licenses",
        "products": "Products"
    },
    "drawer": {
        "userSettings": "User settings",
        "contextButtonHelp": "Click on the hamburger menu in the top bar to switch to another site or to the home screen",
        "mainHelp": "Hover the left-hand menu to expand it, this menu holds the main navigation elements for the current site or home screen"
    },
    "userMenu": {
        "theme": "Theme",
        "dark": "Dark",
        "notification": "Notification | Notifications",
        "pinMenu": "Pin menu",
        "all": "All",
        "success": "Success",
        "info": "Info",
        "resetHints": "Reset hints",
        "settings": "Account settings",
        "logOut": "Log out",
        "confirmLogout": "Please confirm",
        "hintsReset": "Hints have been reset",
        "timeDisplay": "12h Clock"
    },
    "reorderList": {
        "dragDropHelp": "Drag and drop list items to reorder them"
    },
    "baseTable": {
        "selected": "{selected} selected {type} | {selected} selected {type}s",
        "enableMultiselect": "Enable multiselect",
        "disableMultiselect": "Disable multiselect",
        "searchLabel": "Search by all visible fields",
        "searchTooltip": "String keywords together using '&'",
        "export": "Export to excel"
    },
    "channelTables": {
        "channelTable": "TV channel table | TV channel tables",
        "radioChannelTable": "Radio channel table | Radio channel tables",
        "channelTableGroup": "Channel table group | Channel table groups",
        "tableGroup": "Table group | Table groups",
        "dragDropHere": "Drag and drop channels here",
        "newName": "New name",
        "availableChannels": "Available channels",
        "addAllChannels": "Add all channels",
        "selectedChannels": "Selected channels",
        "removeAllChannels": "Remove all channels",
        "unknownChannel": "Unknown channel",
        "noGroups": "There are no groups in this table",
        "table": "Table | Tables",
        "clickToChange": "Click here to change table",
        "createFirst": "Create your first channel table",
        "headerSelectHint": "Click on the table name in the header to select another table for editing",
        "dragDropChannels": "Drag and drop channels to select and reorder them",
        "channelTableName": "Channel table name",
        "new": "New",
        "deleteText": "Delete channel table \"{name}\"?<br>Please ensure that this channel table is not used in any screen configurations.",
        "saveGroupError": "Cannot save group",
        "saveGroupChannelsError": "Error saving channels in group",
        "saveChannelsError": "Error saving channels",
        "appendToGroupError": "Error saving to group",
        "createGroupError": "Error creating group",
        "createdGroup": "Group {name} created",
        "deletedGroup": "Group {name} deleted",
        "channelsSaved": "Channels saved",
        "validationFailedMsg": "Save disabled due to one or more channels missing required fields.",
    },
    "event": {
        "defaultEventValues": "Default event values | Default event values",
        "eventDeleteSuccess": "Event successfully deleted",
        "eventDeleteFailed": "Failed to delete event",
        "createEvent": "Create event",
        "editEvent": "Edit event",
        "deleteEvent": "Delete event",
        "eventPrivate":"Private",
        "startTime": "Start time",
        "startDate": "Start date",
        "endTime": "End time",
        "endDate": "End date",
        "eventHeader": "Events - {date}",
        "eventPrivateHint": "(hide title and host on directions and meeting screens)",
        "eventInFuture": "Event must be in the future",
        "eventEndBeforeStart": "Event must end after start time",
        "eventOverlappingDescription": "An overlapping event already exists at this location, are you sure you want to create this event?",
        "eventOverlapping": "Overlapping events",
        "copyEvent": "Copy event {name}",
        "copyEventToLocation": "Copy event to location",
        "makeCopyEvent": "Make a copy of event",
        "privateTitle": "Private title",
        "noLocations": "No locations found",
        "eventsWithNoLocation": "Events with no location",
        "wayfindingConfiguration": "Direction configuration | Direction configurations",
        "saveWayfindingConfig": "Save direction configuration",
        "saveWayfindingConfigFailed": "",
        "integrationLocations": "Integration location | Integration locations",
        "testConfiguration": "Test configuration",
        "manageLogos": "Manage logos",
        "selectLogo": "Select logo",
        "replaceLogo": "Replace logo",
        "removeLogo": "Remove logo",
        "manageIcons": "Manage icons",
        "eventRetention": "Event Retention",
        "eventRetentionDescription": "Amount of days to retain events in the system after completion.",
        "maxEventDuration": "Duration",
        "maxEventDurationDescription": "Max event duration when booking on room board",
        "applyLogoByHost": "Apply logo by Host name",
        "logoMappingDesc": "Automatically apply a logo when creating an event, based on the Host name. Remember to add Host Mapping to logo for this to work.",
        "hostMapping": "Host Mapping",
        "addHostMapping": "Add host mapping",
        "failedToSaveMapping": "Failed to save mapping",
        "mappingAlreadyExists": "Host mapping already exists on a logo"
    },
    "tvMenu": {
        "tvMenuPage": "TV menu page | TV menu pages",
        "menuPage": "Menu page | Menu pages",
        "subtitle": "Subtitle | Subtitles",
        "audioLanguage": "Audio language | Audio languages",
        "subtitleLanguage": "Subtitle language | Subtitle languages",
        "epgMenu": "EPG menu | EPG menus",
        "offlineMenu": "Offline menu | Offline menus",
        "externalInput": "Ext input menu | Ext input menus",
        "wakeupMenu": "Wakeup order menu | Wakeup order menus",
        "defaultWakeupAlarmMenu": "Default wakeup alarm menu",
        "powerOnMenu": "Power on menu | Power on menus",
        "messageNotificationMenu": "Message notification menu",
        "messageDisplayMenu": "Message display menu"
    },
    "documents": {
        "datasheet": {
            "contextMenu": {
                "columnMenuTooltip": "Column Options",
                "rowMenuTooltip": "Row Options",
                "insertColumnBefore": "Insert column Before",
                "insertColumnAfter": "Insert column After",
                "pasteCopiedColumnBefore": "Paste copied column Before",
                "pasteCopiedColumnAfter": "Paste copied column After",
                "insertRowAbove": "Insert row Above",
                "insertRowBelow": "Insert row Below",
                "pasteCopiedRowAbove": "Paste copied row Above",
                "pasteCopiedRowBelow": "Paste copied row Below",
                "moveColumnLeft": "Move Left",
                "moveColumnRight": "Move Right",
                "moveRowUp": "Move Up",
                "moveRowDown": "Move Down",
                "moveRowTop": "Move to Top",
                "moveRowBottom": "Move to Bottom",
                "changeType": "Change Type",
                "setPermissions": "Set Permissions",
                "manageEnums": "Manage Value List"
            },
            "type": "Table | Tables",
            "enumType": "Value List",
            "mapCoordType": "Map Location",
            "textColumn": "Text Column",
            "imageColumn": "Image Column",
            "mapColumn": "Map Location Column",
            "enumColumn": "Value List Column",
            "mapCoordColumn": "Map Column",
            "columnName": "Column Name",
            "copyColumn": "Copy Column",
            "textCellValidationErrorMsg": "{value} character limit"
        },
        "page": "Page | Pages",
        "document": "Document | Documents",
        "selectPage": "Select a page",
        "dataSheetEnumEditor": "Column Value List",
        "enum": "enum"
    },
    "resources": {
        "resource": "Resource | Resources",
        "font": "Font | Fonts",
        "image": "Image | Images",
        "video": "Video | Videos",
        "document": "Document | Documents",
        "tables": "Table | Tables",
        "playlist": "Playlist | Playlists",
        "template": "Template | Templates",
        "compositeMenuPages": "Signage template page | Signage template pages",
        "logo": "Logo | Logos",
        "wayfindingIcon": "Directional Icon | Directional Icons",
        "uploadedYet": "No {type}s have been uploaded yet",
        "selected": "{amount} {type} selected | {amount} {type}s selected",
        "copyUrl": "Copy URL",
        "copyResources": "Copy resource to {dest}? | Copy {count} resources to {dest}?",
        "moveResources": "Move resource to folder {dest}? | Move {count} resources to folder {dest}?",
        "moveResource": "Move {src} to folder {dst}?",
        "copying": "Copying",
        "copyToFolderSuccess": "Copy {resourceName} to folder {folderName} successful",
        "copyToFolderFailure": "Copy {resourceName} to folder {folderName} failed",
        "copyFinishedWithError": "Finished with error",
        "copySuccessDescription": " | {amount} resource successfully copied to {context} | {amount} resources successfully copied to {context}",
        "circularReferenceErrorMsg": "{amount} {type} have circular references, please edit/remove these to enable saving.",
        "failedToCopy": "Failed to copy",
        "downloadFile": "Download file | Download files",
        "downloadingName": "Downloading {name}",
        "downloadFailed": "Download failed!",
        "selectImage": "Select an image",
        "selectFont": "Select a font",
        "selectVideo": "Select a video",
        "newPlaylist": "New Playlist",
        "upload": "Upload",
        "uploadMedia": "Upload media",
        "uploadResource": "Upload resource",
        "uploadFileType": "Upload {type} | Upload {type}(s)",
        "uploadSuccessful": "Upload successful - {type} is being processed now",
        "uploadError": "Error during upload - please try again",
        "cancelUploadConfirmTitle": "Cancel the remaining upload? | Cancel the {count} remaining uploads?",
        "cancelUploadConfirmMessage": "This will cancel the remaining uploads and close the dialog.",
        "replaceResource": "Replace {resource}",
        "replaceResourceTitle": "Replace {resource}: {name}",
        "replaceResourceDescription": "WARNING! items that are replaced is still cached in cloud, so it will take time before the new image is displayed on screens.<br><br>Are you sure you want to replace the current {resource} with <br>\"{name}\"?",
        "resourceReplaced": "{resource} replaced",
        "resourceReplacedFailed": "Failed to replace {resource}",
        "editImageBefore": "Edit image before uploading it",
        "uploaderTitle": "Upload {type}s",
        "unsupportedFile": "Files of type {type} are not supported",
        "unsupportedFileSize": "Files can't be larger than  {size}",
        "unsupportedFileNameAndSize": "File {fileName} can't be larger than {size}",
        "exceedingMaxPdfPages": "Exceeding max limit of {limit} pages in the PDF",
        "wrongType": "Only {type}s are allowed for upload",
        "quickBrownFox": "The quick brown fox jumps over the lazy dog",
        "addOneOrMore": "Add the images, videos, fonts and PDFs you wish to upload",
        "addOneOrMoreExcludeVideos":  "Add the images, fonts and PDFs you wish to upload",
        "addType": "Add the {type} you wish to upload | Add the {type}(s) you wish to upload",
        "pressUpload": "Press upload to save the added files to the system",
        "rotateLeft": "Rotate 45 degrees left",
        "rotateRight": "Rotate 45 degrees right",
        "crop": "Crop",
        "addResources": "Add resources",
        "uploadSuccessfulHover": "Upload successful",
        "addDocument": "Add document",
        "addTemplate": "Add template",
        "potentialUsageFound": "The following potential use was found:",
        "potentialUsageFoundInItems": "Potential use was found for items:",
        "folderNotEmpty": "Folder is not empty",
        "deleteFolderWithUsage": "These folders contains items with potential usage",
        "deleteFolderWarning": "Deleting this folder will also permanently delete all its contents including sub-folders and resources.",
        "deleteFolderConfirm": "I confirm that I want to permanently delete this folder and its contents",
        "newPlaylistFromPdf": "new playlist from pdf",
        "addDatasheet": "Add Table",
        "displayDuration": "Display duration",
        "pdfUploadFolderError": "Failed to create media folder for PDF, uploading to root media root folder",
        "tag": "Tag | Tags",
        "addTag": "Add tag",
        "transcodeVideo4K": "Video will be available in 4K shortly after upload",
        "transcodeVideoHD": "Video will be available in HD shortly after upload",
        "resizeImage4K": "Image dimensions <br>{width} x {height} exceeds 4K<br>standard(3840 x 2160) and<br>will be resized on upload."
    },
    "mediaChannels": {
        "mediaChannels": "mediaChannels",
        "mediaChannel": "Media channel | Media channels",
        "globalMediaChannels": "Global media channels",
        "globalMapping": "Global mapping",
        "address": "Address",
        "port": "Port",
        "serviceId": "Service ID",
        "frequency": "Freq (kHz)",
        "modulation": "Modulation",
        "symbolRate": "SymRate (kHz)",
        "bandwidth": "Bandwidth",
        "satelliteId": "Satellite ID",
        "polarization": "Polarization",
        "programNumber": "Program number",
        "majorNumber": "Major channel number",
        "minorNumber": "Minor channel number",
        "type": "Type",
        "audioOnly": "Audio only",
        "createChannel": "Create {type} channel",
        "save": "Save channels",
        "saveTooltip": "This will save channels of all types",
        "clickToChange": "Click here to change channel type",
        "reset": "Reset and refresh channels",
        "types": "Channel types",
        "webRadioSearch": "Web radio search",
        "unknownBitrate": "Unknown",
        "placeholderChannelName": "Channel name",
        "updateFailed": "Media channels update failed",
        "deleteFailed": "Could not delete channel!",
        "createFailed": "Could not create channel!",
        "updateSuccess": "Media channels updated!",
        "deleteSuccess": "Media channel deleted!",
        "createSuccess": "Media channel created!",
        "validateFailed": "A channel of type {type} has an empty name"
    },
    "templates": {
        "templateErrorMessages": {
            "deserializationErrorMsg": "Template Engine could not parse the API data. Check Fixed Parameter / Test / API Connector settings to ensure correct data is fetched."
        },
        "dataFetchErrorMessages": {
            "unknownHost": "URL is unknown when trying to fetch data from API. This could be caused by a typo in API Connector URL and/or in fixed/test path parameter.",
            "protocolError": "Error in HTTP protocol when trying to fetch data from API. This could be caused by a typo in API Connector URL and/or fixed/test path parameter.",
            "unknownError": "Error while fetching API Data. Check API Connector / Fixed/test parameter settings.",
            "code300ErrorMsg": "API returned code 300 - Multiple Choices, an indication that there exists multiple options for the requested resource. Check test / fixed parameter settings.",
            "code400ErrorMsg": "API returned code 400 - Bad Request, API could not understand the request due to invalid syntax. Check Connector / test / fixed parameter settings.",
            "code401ErrorMsg": "API returned code 401 - Unauthorized, You are not authorized to make this request. Check the API Connector for correct headers",
            "code403ErrorMsg": "API returned status code 403 - forbidden. This could mean that Connector or fixed parameters is set up with incorrect, invalid or outdated authorization.",
            "code404ErrorMsg": "API returned status code 404 - Not Found. This can mean that the url is valid but the resource itself does not exist. APIs may also send this response instead of 403 to hide the existence of a resource from an unauthorized client. Make sure Connector / test /fixed parameter settings are correct.",
            "code406ErrorMsg": "API returned code 406 Not acceptable, API didn't find any content that conforms to the criteria given. Check test / fixed parameters.",
            "code415ErrorMsg": "API Returned code 415 - Unsupported Media Type. Check API Connector settings. Try to set Content-Type custom header to a format supported by the API",
            "code500ErrorMsg": "API returned status code 500 - Internal Server error. Check test / fixed parameter settings.",
            "apiTimeout": "API response timed out. API might be temporarily unavailable."
        },
        "serializationErrors": {
            "jsonParseError": "Could not parse the JSON data returned from API, please check API Connector / fixed parameter settings."
        },
        "internalErrors": {
            "code500ErrorMsg": "There is an issue with our systems, please try again in a little bit."
        },
        "noDataYet": "No data yet.",
        "noDataFoundYet": "No data found yet!",
        "save": "Save template without closing window",
        "setATest": "Set a test {name}",
        "infoUrlDefault": "URL to API info page",
        "infoUrlTooltip": "How to use source",
        "error": "Error evaluating Template",
        "httpMethodRequired": "HTTP method required",
        "nameRequired": "Name required",
        "paramType": "Parameter type",
        "addItem": "Add item",
        "preview": "Generate preview",
        "template": "Template | Templates",
        "templateRequired": "Template required",
        "reset": "Reset changes made to this template since last save",
        "source": "Source | Sources",
        "sourceRequired": "Source required",
        "fixedParameters": "Fixed parameters",
        "setAFixed": "Set a fixed {name}",
        "templateExample": "Template example",
        "dataExample": "Data sample",
        "httpMethod": "HTTP method",
        "markup": "Markup",
        "sourceError": "Error: Source failed to load",
        "selectConfigure": "Select and configure template",
        "failedSource": "Error: Failed to find template source",
        "failedSourceFetch": "Error: Failed to fetch source info",
        "templateSource": "Template source | Template sources",
        "rootName": "Root name",
        "apiConnector": "API Connector | API Connectors",
        "createTemplate": "Create template",
        "createWithoutClosing": "Create template without closing window",
        "saveWithoutClosing": "Save template without closing window",
        "fetchApiData": "Fetch API data",
        "cacheForSeconds": "Caching not enabled (0 seconds) | Cache API Template for {value} second | Cache API Template for {value} seconds",
        "cacheForSecondsError": "Number must be between 0 and 1800",
        "defaultTemplateErrorExplanation": "Content to be displayed on TV if Template contains errors."
    },
    "configSchedules": {
        "defaultConfig": "Default",
        "dailyOverrides": "Daily overrides",
        "weeklyOverrides": "Weekly Overrides",
        "editTimeslot": "Edit timeslot",
        "deleteTimeslot": "Delete timeslot",
        "dragStopTime": "Drag to change stop time",
        "defaultContentExplanation": "This configuration runs all day on the screen unless overridden by daily, weekly or calendar-based configurations.",
        "editScheduleName": "Edit schedule name",
        "tabDefault": "Default",
        "tabDaily": "Daily",
        "tabWeekly": "Weekly",
        "tabCalendar": "Calendar",
        "addTimeslotHelpText": "Click to add a new timeslot, or just click on an opening in the calendar."
    },
    "scheduleMapping": {
        "createNew": "Create new schedule mapping",
        "rename": "Rename {title}",
        "created": "New schedule mapping created",
        "updated": "Schedule mapping updated",
        "currentSchedule": "Current schedule",
        "deleteHeading": "Delete Schedule Mapping {title}?",
        "deleteText": "Any screens that are part of this mapping will be moved to the default schedule mapping group.",
        "addMapping": "Add mapping",
        "screens": "Screen | Screens",
        "screensHeadingInfo": "TV-screens using ruleset. Right-click to move.",
        "moveTo": "Move to...",
        "rulesHeading": "Rules are evaluated in the order they appear here",
        "rulesDescription": "Custom rules are not yet supported.",
        "newRule": "New rule",
        "defaultSchedule": "Default schedule",
        "screensMoved": "Screens moved",
        "createFailed": "Could not create mapping",
        "updateFailed": "Could not update mapping"
    },
    "schedulePlans": {
        "editor": {
            "notInUse": "Schedule Plans not in use. Press 'Create' to start using.\n" +
                "We'll automatically generate a 'Default' Schedule Plan that contains your current schedule/screen setup in addition to the new Schedule Plan you create.",
            "newForEach": "Separate Schedules for each screen",
            "singleNew": "Shared Schedule for all screens",
            "copyExisting": "Copy existing Schedule Plan",
            "removeWhenActive": "<b>This Schedule Plan is currently set to Active</b>. If you choose to remove it all Screens will be switched to the Default Schedule Plan. <br><br> Are you sure you wish to remove this Schedule Plan?"
        },
        "screenEditor": {
            "newScheduleForEach": "Create New Schedule for each Schedule Plan",
            "individualSelectionDescription": "Individual Selection"
        },
        "type": "Schedule Plan | Schedule Plans",
        "setActive": "Set active",
        "setActiveConfirmTitle": "Set active Schedule Plan?",
        "disabledDeleteForDefault": "Delete Default Schedule Plan is only enabled when there are no other Schedule Plans",
        "setActiveConfirmText": "This action will set Schedule Plan '{title}' active. All screens on this site will have schedules defined under Schedule Plan {title} set as their active schedules. Do you wish to proceed?",
        "setActiveSuccess": "Schedule Plan {name} set active",
        "setActiveFailed": "Could not set Schedule Plan {name} active"
    },
    "screens": {
        "screensMissingSchedulesTitle": "Screens missing Schedules",
        "screensMissingSchedulesText": "The following Screens are missing schedules, this may have been caused by the interruption of the 2-step process for Creating/Moving Screens.",
        "screenStatus": "Screen status | Screen status",
        "tvScreenStatus": "TV screen status",
        "signageDisplayStatus": "Signage display status",
        "screens": "Screens",
        "extendedId": "Extended ID",
        "livePreview": "Live Preview",
        "displays": "Displays",
        "onlineScreens": "online",
        "screenSetup": "Screen setup | Screen setups",
        "screenSetupCreated": "Screen setup created",
        "screenSetupUpdated": "Screen setup updated",
        "defaultScreenConfig": "Default configuration",
        "tvScreen": "TV screen | TV screens",
        "signageScreen": "Signage screen | Signage screens",
        "tvType": "TV type | TV types",
        "clientType": "Client type | Client types",
        "tvSoftware": "TV software",
        "editName": "Edit name",
        "tvClient": "TV client | TV clients",
        "unknownSetup": "Unknown setup",
        "createFirstSchedule": "Create your first schedule",
        "schedule": "Schedule | Schedules",
        "copyFromConfig": "Copy from config (optional)",
        "quicklyCopyConfig": "Quickly copy another config",
        "mainMenu": "Main menu | Main menus",
        "welcomeMenu": "Welcome menu | Welcome menus",
        "channelMenu": "TV channel menu | TV channel menus",
        "radioChannelMenu": "Radio channel menu | Radio channel menus",
        "startVolume": "Start volume",
        "maxVolume": "Max volume",
        "autoPowerOffHrs": "Auto power off after {hrs} hrs",
        "enableAutoPowerOff": "Enable auto power off",
        "audioLanguageMenu": "Audio language menu",
        "subtitleLanguageMenu": "Subtitle language menu",
        "externalInputMenu": "External input menu | External input menus",
        "noScreenSetups": "No screen setups have been created yet",
        "selectSlotType": "Select slot type",
        "scheduleEditorCalendarHint": "Click anywhere on the calendar to add a new timeslot",
        "editSlot": "Edit slot",
        "newSlot": "New slot",
        "createNewConfig": "Create new configuration",
        "timeslotOverlap": "The selected start and end time overlaps with another timeslot. Please try again with different times.",
        "volume": "Volume",
        "durationRequired": "Duration required",
        "durationLongerThan": "Duration must be longer than 0 seconds",
        "messageInJson": "Message in JSON format",
        "validJson": "Must be valid JSON",
        "sendMessageToScreen": "Send {type} message to {screens} screen | Send {type} message to {screens} screens",
        "sendMessage": "Send message",
        "updateStatus": "Update status",
        "pushConfig": "Push configuration",
        "pushContent": "Push content",
        "upgradeAgentSoftware": "Upgrade Agent SW",
        "upgradeAgentSoftwareNotification": "This will send a message to the Agent to upgrade its software. " +
                "As part of the upgrade the Agent may restart, which will lead to a short outage of the Agent service. " +
                "Please note that if no updates are available then no actions will be performed.",
        "poweredOn": "display on",
        "poweredOff": "in standby",
        "powerOn": "Power on display",
        "powerOff": "Go to standby",
        "reboot": "Reboot",
        "rebootRequired": "Applying screen rotation requires a reboot.<br><br>Do you wish to reboot now?",
        "jvmUptime": "JVM uptime",
        "rebootRequested": "Reboot requested",
        "appUpdateRequested": "Application update requested",
        "setVolume": "Set volume",
        "displayMessage": "Display message",
        "displayAddress": "Show address",
        "displayName": "Show name",
        "resetSoftAP": "Reset SoftAP password",
        "displayPage": "Display page",
        "tuneChannel": "Tune Channel",
        "enableRemoteDebug": "Enable remote debug",
        "enableOnscreenDebug": "Enable on-screen debug",
        "disableOnscreenDebug": "Disable on-screen debug",
        "reAuthenticate": "Re-authenticate",
        "reAuthenticateMsg": "You are about to re-authenticate the screen {screen}. Please confirm to continue.",
        "getScreenshot": "Get Screenshot",
        "sendPushMessage": "Send push message",
        "openClientPreview": "Open client preview",
        "sendMgmntMessage": "Send Mgmnt Message",
        "openDebugInfo": "Show debug info",
        "moveToLocation": "Move to location",
        "changeSchedule": "Change schedule",
        "selectSchedule": "Select schedule",
        "manage": "Manage",
        "moveScreens": "Move {screens} screen | Move {screens} screens",
        "deleteScreens": "Delete {screens} screen? | Delete {screens} screens?",
        "setContentScheduleForScreenExistingNameMsg": "Schedule with name {screenName} already exists, new schedule with name {uniqueName} will be created.",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "Name conflict in the following Schedule Plans:<br>",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "<span style='font-weight: bold;'>{schedulePlanName}</span> - New schedule with name <span style='font-style: italic;'>{uniqueName}</span> will be created.<br>",
        "move": "Move",
        "name": "Name",
        "ip": "IP",
        "mac": "MAC",
        "rotation": "Rotation",
        "deviceType": "Device type",
        "appVersion": "App version",
        "firmwareVersion": "Firmware version",
        "osVersion": "OS version",
        "details": "Details",
        "occupied": "Occupied",
        "online": "Online",
        "offline": "Offline",
        "power": "Power",
        "state": "State",
        "public": "Public",
        "clientId": "Client ID",
        "setRotation": "Set rotation for screen | Set rotation for {count} screens",
        "id": "Client ID",
        "locationTitle": "Location",
        "previewOnlyOne": "Please select only one for screen for preview",
        "screensMoved": "{screens} screen moved to another location | {screens} screens moved to another location",
        "installCode": "Install code | Install codes",
        "createInstallCode": "Create install code",
        "code": "Code",
        "createSchedule": "Create a schedule",
        "created": "Created",
        "createdBy": "Created by",
        "expiryDate": "Expiry date",
        "configInUse": "Error: Configuration is being used",
        "configInUseDescription": "{name} is in use by the following Schedules:",
        "scheduleInUse": "Error: Schedule is being used",
        "scheduleInUseDescription": "{name} is in use by the following Schedule Mappings:",
        "configTvSettingsHeading": "TV Settings",
        "configChannelsHeading": "Channels",
        "configMenusHeading": "Menus",
        "addConfiguration": "Add configuration",
        "groupBy": "Group by",
        "setSchedule": "Set schedule...",
        "upgradeApp": "Upgrade Application",
        "helpText": "Enter message to display before powering off.<br>Setting no message will instantly power off screen(s).",
        "newTvScreen": "New TV screen",
        "clientIdRequired": "Client ID required",
        "pickLocation": "Pick a location",
        "noLocation": "There are no available locations",
        "roomRequired": "Room required",
        "roomSearchPlaceholder": "Start typing to search",
        "tvPreRegisteredOn": "TV pre-registered on",
        "tvRegisteredOn": "TV registered on",
        "tvRegisteredCloseText": "Please close this tab.",
        "pickContext": "Pick a context",
        "noContext": "There are no available contexts",
        "contextRequired": "Context required",
        "clientToken": "Client token",
        "clientTokenRequired": "Client token required",
        "couldNotFindItemToEdit": "Could not find item to edit",
        "uptime": "Uptime",
        "memoryUsage": "Mem use",
        "requestScreenshot": "Requesting screenshot, please wait...",
        "requestScreenshotOffline": "Cannot request screenshot from offline device...",
        "requestScreenshotPoweredOff": "Cannot request screenshot from device in standby...",
        "requestNewScreenshot": "Click to request a new screenshot...",
        "requestUpdate": "Request update",
        "serial": "Serial",
        "firmware": "Firmware",
        "resolution": "Resolution",
        "system": "System",
        "publicIp": "Public IP",
        "softAP": "Soft AP",
        "currentConfig": "Current config",
        "rcAddr": "RC addr",
        "airServerEnabled": "AirServer enabled",
        "network": "Network",
        "timeOnDevice": "Time on device",
        "openPreview": "Open preview",
        "screenInfo": "Screen info",
        "reloadAllMediaTitle": "Reload all media",
        "reloadAllMediaDescription": "Delete all downloaded media on {amount} device and re-download? | Delete all downloaded media on {amount} devices and re-download?",
        "moveToScheduleTitle": "Set schedule mapping",
        "changeScreenScheduleDescription": "Change screen schedule to {mapping}?",
        "changeScreenScheduleSuccess": "Changed screen schedule to {mapping}",
        "moveToScheduleDescription": "move {amount} screens to schedule {mapping}?",
        "moveToScheduleSuccess": "New schedule set for {amount} screens",
        "moveToSite": "Move screen to site",
        "moveScreenToSiteMsg": "Warning! You are about to move this screen to another site, please confirm to continue.",
        "moveScreenToSiteSuccess": "Screen moved to new site",
        "moveScreenToSiteNotAllowed": "Not allowed to move screen to this site.",
        "moveScreenToSiteNotAllowedNoLicense": "Not allowed to move screen to this site: Site does not have {license} license activated.",
        "moveScreenToSiteNotAllowedLimitReached": "Not allowed to move screen to this site: License limit for this screen type has been reached.",
        "createScreenLimitReachedMsg": "Unable to create new screen. License limit for this screen type has been reached",
        "successfullyAuthenticated": "Screen successfully re-authenticated",
        "canNotReAuthenticate": "Can't re-authenticate an already authenticated Screen",
        "appMode": "Application mode",
        "forceUpdate": "Force Update",
        "services": "Services",
        "upgradablePackages": "Upgradable packages",
        "authCodeNotFound": "Authentication code is not valid or has timed out. Please check the screen for a valid code.",
        "lastReboot": "Last reboot",
        "storage": "Storage",
        "authCreateNewSchedule": "This will create a new schedule with the same name as the created screen and place the screen in that schedule.<br>By un-checking this box, you can choose to add the screen to an already existing schedule.",
        "authUseExistingSchedule": "Select an already existing schedule to add your new screen to:",
        "registrationCode": "Screen registration code",
        "registrationCodeLength": "6 digits",
        "startApp": "Start native app"
    },
    "booking": {
        "booking": "Booking | Bookings",
        "numberOfBookings": "{number} booking | {number} bookings",
        "book": "Book",
        "bookNights": "Book {nights} night stay",
        "night": "Night | Nights",
        "stayFromTo": "{from} to {to}",
        "stayCheckedIn": " - checked in",
        "calendar": "Calendar",
        "availabilityError": "Failed to retrieve booking availability data",
        "bookingsForDate": "Bookings for {date}",
        "noBookingsForDate": "No bookings for {date}",
        "failedToRetrieve": "Failed to retrieve bookings",
        "timeslotTaken": "That timeslot is taken, please try another one"
    },
    "guests": {
        "anonymous": "Anonymous",
        "language": "Language",
        "unspecified": "Unspecified",
        "pickRoom": "Pick an available room",
        "checkInDate": "Check-in date",
        "checkInTime": "Check-in time",
        "checkOutDate": "Check-out date",
        "checkOutTime": "Check-out time",
        "newCheckIn": "New check-in",
        "checkIn": "Check-in",
        "checkInGuest": "Check in Guest",
        "editGuest": "Edit guest",
        "checkOut": "Check-out",
        "getBill": "Get bill",
        "guestCheckOut": "Check out guest",
        "billItemName": "Item name",
        "billItemAmountMax4Chars": "amnt",
        "billItemCurrencyMax3Chars": "cur",
        "billDataError": "Could not fetch bill info ({status})",
        "confirmCheckout": "Confirm checkout",
        "editStay": "Edit stay",
        "editBooking": "Edit booking",
        "newBooking": "New booking",
        "checkInBeforeOut": "Check-out must come after check-in.",
        "noGuests": "No guests",
        "overrideTvConfig": "Override TV config",
        "setTvConfigConfirm": "Override the TV configuration mapping \"{configName}\" on the selected {guestCnt} guest(s)?<br>This configuration will be reverted at check-out.",
        "clearTvConfigConfirm": "Clear the overridden TV configuration mapping on the selected {guestCnt} guest(s)?<br>This will apply the system-set configuration mapping.",
        "setTvConfigSuccess": "{amount} TV have been reconfigured|{amount} TVs have been reconfigured",
        "sendMessage": "Send message",
        "guestsNumber": "{number} guest | {number} guests",
        "person": "Person | Persons",
        "forceCheckOutTitle": "Force check-out",
        "forceCheckOutMessage": "This guest was automatically checked in via an external system. The external system is responsible for checking the guest out again. Forcing this checkout will not check the guest out from the external system and might cause problems. Proceed?"
    },
    "guestMessages": {
        "to": "To",
        "unreadMessages": "Unread messages",
        "subject": "Subject",
        "create": "New message",
        "view": "View message",
        "createdHeader": "Sent",
        "readHeader": "Read",
        "displayedHeader": "Notified",
        "senderName": "From",
        "instant": "Instant display",
        "messageTemplate": "Message Template | Message Templates",
        "useTemplate": "Use template"
    },
    "wakeups": {
        "roomAccount": "Room (account)",
        "tvMenu": "TV menu to display",
        "customTvMenu": "Use custom TV menu to display",
        "wakeupInFuture": "Wakeup must be in the future",
        "wakeupExists":"Wakeup already exists",
        "create": "Create wakeup",
        "daily": "Daily",
        "status": "Status",
        "delivered": "Delivered",
        "notDelivered": "Not delivered",
        "displayed": "Displayed",
        "notDisplayed": "Not displayed",
        "confirmed": "Confirmed",
        "notConfirmed": "Not confirmed",
        "editWakeup": "Edit wakeup",
        "deliveredWakeup": "Delivered wakeup",
        "newWakeup": "New wakeup"
    },
    "scheduledTasks": {
        "dailyNewSheets": "Daily new sheets",
        "roomType": "Type of room | Room types",
        "allTypes": "All types",
        "roomTypeEmpty": "Leave empty to set this task to all rooms",
        "when": "When",
        "createTask": "Create task",
        "specificRooms": "Specific rooms"
    },
    "issues": {
        "maintenanceType": "Maintenance type | Maintenance types",
        "changeStatus": "Change status",
        "setIssueTo": "Set issue to...",
        "editIssue": "Edit issue",
        "created": "Created",
        "closed": "Closed",
        "issueSetTo": "Issue on {room} set to {status}",
        "issuesSetTo": "{issues} issues set to {status}",
        "newIssue": "New issue | New issues",
        "editingIssues": "Editing {issues} issues",
        "issueUpdated": "{type} issue on {room} updated",
        "issueAdded": "{type} issue added to {room}",
        "urgent": "Urgent",
        "flagAsClosed": "Flag as closed",
        "issueSetup": "Issue setup"
    },
    "locations": {
        "location": "Location | Locations",
        "createRooms": "Create rooms",
        "createLocations": "Create locations",
        "occupation": "Occupation",
        "locatedIn": "Located in",
        "locationSetTo": "{location} set to {status}",
        "locationsSetTo": "{locations} rooms set to {status}",
        "locationSetup": "Location setup",
        "category": "Category | Categories",
        "setRoomTo": "Set {room} to...",
        "editRoom": "Edit room",
        "changeStatus": "Change status",
        "changeCategory": "Change category",
        "changeLocation": "Change location",
        "addTag": "Add tag",
        "removeTag": "Remove tag",
        "addIssue": "Add issue",
        "tagRemoved": "Tag {tag} removed from {rooms} room | Tag {tag} removed from {rooms} rooms",
        "tagAdded": "Tag {tag} added to {rooms} room | Tag {tag} added to {rooms} rooms",
        "categoryChange": "{room} category changed to {category} | {rooms} changed to category {category}",
        "newRooms": "New rooms",
        "newLocations": "New locations",
        "roomNumber": "Room number",
        "locationNumber": "Location number",
        "startRange": "Start range",
        "endRange": "End range",
        "parentLocations": "Parent locations",
        "startRangeRequired": "Start range is required",
        "startRangeLower": "Start range must be lower than end range",
        "endRangeRequired": "End range is required",
        "endRangeHigher": "End range must be higher than start range",
        "change": "Change",
        "newLocation": "New location",
        "changeLocationOf": "Change location of {room} | Change location of {rooms} rooms",
        "locationChangeSuccess": "Location change successful",
        "roomTag": "Room tag | Room tags",
        "newTag": "New tag",
        "newStatus": "New status",
        "issueDeletedFrom": "Issue deleted from {room}",
        "locationCategory": "Location category | Location categories",
        "roomCategory": "Room category | Room categories",
        "newCategory": "New category",
        "bedroomTv": "Bedroom tv",
        "meetingRoomTV": "Meeting room tv",
    },
    "serviceConfig": {
        "saveConfig": "Save config",
        "disableDailyRebootWarning": "Warning: This will keep some devices from receiving automated software updates",
        "enableDefaultSubtitle": "Show subtitles automatically",
        "enableDefaultSubtitleTooltip": "Enable this if you wish subtitles to be automatically activated when watching TV - click for more info",
        "forceTvMode": "Show TV channels on power-on",
        "forceTvModeTooltip": "Enable this if you wish to go directly into TV Channels instead of loading the Main menu page when starting TV screens - click for more info",
        "rebootTime": "Reboot time",
        "rebootTimeInvalid": "Reboot time invalid",
        "instantContentPush": "Instant content push",
        "instantContentPushDescription": "Enabling this will push all content changes to affected devices immediately with no prompt.",
        "useInternalURL": "This will make the TV's use the internal URL for local content.",
        "dailyCheckinTime": "Daily check-in time",
        "dailyCheckoutTime": "Daily check-out time",
        "dailyCheckoutTimeBeforeCheckin": "Check-out time must be after check-in time",
        "enableLGAirServer": "Enabling requires an account number to be input which is used for provisioning the certificates for AirServer",
        "lgAirServerCodeLabel": "LG AirServer code"
    },
    "login": {
        "terms": "Terms",
        "resetPassword": "Reset password",
        "language": "Language",
        "username": "Username",
        "password": "Password",
        "logIn": "Log in",
        "cloudManagementPortal": "Management portal",
        "enterUsername": "Enter your username and password",
        "enterEmail": "Enter your email address",
        "invalidEmail": "Invalid email address",
        "resetMessage": "Further instructions have been sent to {email}",
        "resetFailedMessage": "Reset password request failed: {message}",
        "setNewPassword": "Enter new password",
        "redirectToLoginPageTitle": "Redirect to login page",
        "redirectToLoginPageText": "You will now be redirected to the login page."
    },
    "latLng": {
        "openMap": "Open",
        "lat": "Latitude",
        "lng": "Longitude",
        "selectPosition": "Select a location",
        "addressHint": "You can manually type in address",
        "addressWarning": "Make sure latitude and longitude matches address",
        "searchFieldLabel": "Search for address",
        "address": "Address",
        "locationAddressErrorMessage": "Contact your license administrator to add address information",
        "maxLength": "Max length"
    },
    "folder": {
        "newResources": "New resources",
        "uploadFile": "Upload file",
        "folder": "Folder | Folders",
        "noFolders": "There are no folders yet",
        "doubleClickSelect": "Double click to select",
        "newFolder": "New folder",
        "copy": "Copy",
        "cut": "Cut",
        "paste": "Paste",
        "selected": "{amount} selected {type} | {amount} selected {type}s",
        "deleting": "Deleting {amount} item | Deleting {amount} items",
        "root": "Root",
        "home": "Home",
        "emptyFolder": "This folder is empty",
        "createFolder": "Create folder",
        "deleteFolder": "Delete folder",
        "moveToFolder": "Move to folder",
        "copyToFolder": "Copy to folder",
        "editFolder": "Edit folder",
        "imageSize": "Image size",
        "unknownImageSize": "Unknown image size"
    },
    "auth": {
        "loginNotAvailable": "Service not available - please try again later",
        "wrongUserPass": "Wrong username and/or password",
        "responseError": "Error in response from server",
        "noCredentials": "Credentials not provided - unable to login",
        "errorMirageSession": "You have successfully logged in, but there was a problem loading the portal.<br><br>Try reloading or contact your manager/support.",
        "errorMirageSessionPPDS": "You have successfully logged in, but your user account does not have access to any PPDS Wave customers.<br><br>Try reloading or contact your manager/support.",
        "invalidMirageSession": "You have successfully logged in, but access to The Cloud Portal has been disabled.<br><br>Try a different login provider or contact your manager/support.",
        "invalidMirageSessionPPDS": "You have successfully logged in, but access to PPDS Wave Creator has been disabled.<br><br>Try a different login provider or contact your manager/support.",
        "unknownMirageSession": "You have successfully logged in, but an unknown error has occurred.<br><br>Try a different login provider or contact your manager/support."
    },
    "accessPanel": {
        "resellerTitle": "Reseller user access management",
        "resellerDescription": "Configure user and API access to:<br>a) manage the reseller to provision sites and licenses(<span style='font-weight: bold;'>Account Access</span>). This does NOT grant access to the sites.<br>b) access this resellers sites(<span style='font-weight: bold;'>Site Access</span>). This grants the given access level to ALL sites that belong to this reseller.",
        "distributorTitle": "Distributor user access management",
        "distributorDescription": "Configure user and API access to:<br>a) manage the distributor to provision resellers(<span style='font-weight: bold;'>Account Access</span>). This does NOT grant access to the resellers.<br>b) access this distributors resellers(<span style='font-weight: bold;'>Reseller Access</span>). This grants the given access level to ALL resellers that belong to this distributor.",
        "domainTitle": "Domain user access management",
        "domainDescription": "Configure user and API access to:<br>a) manage the domain to provision distributors(<span style='font-weight: bold;'>Account Access</span>). This does NOT grant access to the distributors.<br>b) access this domain distributors(<span style='font-weight: bold;'>Distributor Access</span>). This grants the given access level to ALL distributors that belong to this domain.",
        "resellerAccess": "Reseller access",
        "sharingContextsAccess": "Sharing contexts access",
        "contentProviderContextsAccess": "Content provider access",
        "deploymentAccess": "Site access",
        "themeAccess": "Theme access",
        "distributorAccess": "Distributor access",
        "domainAccess": "Domain access",
        "accessControl": "Access control",
        "accessControlDescription": "Select the reseller to edit",
        "accessError": "You do not have access to user administration for any {contextType}s"
    },
    "theme": {
        "theme": "Theme | Themes",
        "importWindowHeader": "Set destination folder",
        "editThemeImage": "Edit theme image",
        "imagePlaceholder": "No image set",
        "editThemeCard": "Edit theme card",
        "publishSnapshot": "Publish theme",
        "publishDescription": "Publishing a theme will overwrite any previous published version. It has no effect on sites that imported the previous versions.",
        "publishSharingDescription": "Sharing of the published version is set up under Home -> Reseller -> Themes. You'll need reseller sharing access to set this up.",
        "lastPublished": "Last published",
        "ThemeNotPublished": "Theme is currently not published",
        "resourceStats": "Resource stats",
        "editorInfo": "Note that you should check image copyrights and general readiness of the entire bundle of elements before you click \"Publish Snapshot\", allowing all designated user accounts to import the theme",
        "noFeaturesActivated": "No features activated...",
        "importInfo": "Import complete interactive menu themes. None of the existing content will be changed",
        "importInfoTheme": "Import other themes into this theme.",
        "import": "Import",
        "folderExists": "Folder already exists",
        "importLoader": "Importing theme",
        "importSuccess": "Your theme has been successfully imported and can be found under \"Content\"",
        "importSuccessAsync": "The import job has started. Please wait a couple of minutes before working with the imported {context}.",
        "importError": "Delete folders with the name: {folder} under \"Content\" and try importing again",
        "previewConfiguration": "Theme preview configuration",
        "savePreviewError": "Could not save config!",
        "launchPreview": "Launch preview",
        "noConfigurationFound": "ERROR: No configuration sets found...",
        "themePublished": "Theme published",
        "published": "published",
        "themePublishedError": "Unable to publish theme",
        "themeSharingSuccess": "Theme sharing updated",
        "themeSharingError": "Unable to update sharing",
        "themeEmptyError": "Cannot set sharing before publishing the theme",
        "fetchError": "Unable to fetch current share data",
        "editTitle": "Edit card to set a title.",
        "editDescription": "Edit card to set a description.",
        "shareWith": "Share with:",
        "shareAll": "All",
        "shareSubGroup": "Sub-group",
        "shareSingle": "Single",
        "successfullyImported": "Successfully imported",
        "successfullyImportedAsync": "Import job started",
        "importFailed": "Import failed",
        "newTheme": "New theme",
        "preview": "Theme preview",
        "previewNotAvailable": "Preview not available - contact support",
        "previewInfo": "Launch a virtual TV-client in your browser to test the theme.<br>This preview will not be watermarked.",
        "loadThemesError": "Unable to fetch themes",
        "themeControl": "Theme control",
        "themeControlDescription": "Select the theme to edit",
        "enablePublishInfo": "Set all the publishing info(title, description and theme image) to enable publishing",
        "enableSharing": "You must set at least one share for your theme to be able to publish it",
        "panel": {
            "title": "Reseller theme management",
            "description": "Configure themes"
        },
        "themeName": "Theme name"
    },
    "sharingContext": {
        "type": "Sharing Context | Sharing Contexts",
        "rootFolderPrefix": "Provided",
        "new": "New Resource Provider",
        "deleteConfirmTitle": "Delete Resource Provider",
        "deleteConfirmMsg": "Please confirm that you wish to delete this resource provider",
        "editorInfo": "Be mindful that any changes made to the media in this resource provider context will potentially be instantly available to all the sites that this provider shares to.<br>Also be VERY careful of deleting items as they might be in use by sites.",
        "deleteWarning": "WARNING! Be VERY careful of deleting items as they might be in use by sites",
        "deleteConfirm": "Confirm that I wish to delete this {type} | Confirm that I wish to delete these {type}s"
    },
    "contentProviderContext": {
        "type": "Content Provider Context | Content Provider Contexts",
        "rootFolderPrefix": "Provided",
        "new": "New Content Provider",
        "deleteConfirmTitle": "Delete Content Provider",
        "deleteConfirmMsg": "Please confirm that you wish to delete this content provider",
        "editorInfo": "Be mindful that any changes made to the media in this resource provider context will potentially be instantly available to all the sites that this provider shares to.<br>Also be VERY careful of deleting items as they might be in use by sites.",
        "deleteWarning": "WARNING! Be VERY careful of deleting items as they might be in use by sites",
        "deleteConfirm": "Confirm that I wish to delete this {type} | Confirm that I wish to delete these {type}s",
        "resourceStats": "Resource stats",
        "enablePublishInfo": "Set all the publishing info(title, description and theme image) to enable publishing",
        "enableSharing": "You must set at least one share for your content to be able to publish it",
        "publishSnapshot": "Publish content",
        "publishDescription": "Publishing will overwrite any previous published version.",
        "publishSharingDescription": "Sharing of the published version is set up under Home -> System -> Content Providers. You'll need system sharing access to set this up.",
        "lastPublished": "Last published",
        "contentPublished": "Content published",
        "contentPublishedError": "Unable to publish content",
        "preview": "Content Provider preview",
        "previewInfo": "Launch a virtual TV-client in your browser to test the content provider.<br>This preview will not be watermarked.",
        "previewNotAvailable": "Preview not available - contact support",
        "previewConfiguration": "Content Provider preview configuration",
    },
    "signage": {
        "signage": "Signage",
        "schedule": "Schedule|Schedules",
        "addTimeslot": "Add timeslot",
        "contentSchedule": "Content Schedule|Content Schedules",
        "noSchedules": "No schedules have been set up. Add a screen using the installer code, or create a schedule.",
        "mergeDefaultTitle": "Merge with Default content",
        "mergeDefaultConfirm": "Are you sure you wish to merge this timeslot with default content?",
        "mergeDefaultTimeslotTitle": "MERGING WITH DEFAULT CONTENT",
        "startEndTimeInvalidMsg": "End time is earlier than start time! Saving has been disabled.",
        "durationInvalidMsg": "Minimum duration is 5 minutes! Saving has been disabled.",
        "manageDefaultContentBtn": "Manage default content",
        "defaultContentDescription": "This content runs all day on the screen unless overridden by daily, weekly or calendar-based content.",
        "upcoming": "Upcoming",
        "addNewDailyRecurring": "Add new daily recurring timeslot",
        "addNewWeekly": "Add new weekly recurring timeslot",
        "addNewCalendarTimeslotFor": "Add new calendar timeslot for ",
        "editDefaultContent": "Edit default content",
        "editDailyRecurringTimeslot": "Edit daily recurring timeslot",
        "editWeeklyRecurringTimeslot": "Edit daily recurring timeslot",
        "editCalendarTimeslotFor": "Edit calendar timeslot for ",
        "contentListTitle": "Drag assets from right side into list below to build your playlist",
        "durationLabel": "Duration(secs)",
        "deleteContentListEntry": "Delete entry",
        "interactivityDisplayDelayLabel": "Int. delay",
        "interactivityDisplayDelay": "Interactivity display delay",
    },
    "pms": {
        "pmsHeader": "Property Management System configuration",
        "vendor": "Vendor",
        "accessToken": "Access token",
        "propertyCode": "Property code",
        "tigerAccessTokenHint": "Authentication code for TigerTMS API - Also called 'wsUserKey' in TigerTMS systems",
        "siteName": "Site name",
        "tigerSiteNameHint": "Unique identifier for this hotel, will be generated by TigerTMS",
        "directoryId": "Directory ID",
        "soapApiUrl": "SOAP API Endpoint",
        "soapApiUrlHint": "URL for communication with TigerTMS iLink - Defaults to 'https://checkout.tigertms.com/GenericEXT/' if left empty",
        "linkCheckerIn": "Enable status monitoring for INCOMING communication to TigerTMS",
        "linkCheckerOut": "Enable status monitoring for OUTGOING communication to TigerTMS",
        "enableDailyReset": "Enable daily reset",
        "pmsLink": "PMS link",
        "lastMsgReceived": "(Last received {time} ago)",
        "hour": "hour",
        "minute": "minute",
        "forceSync": "force sync",
        "forceSyncSuccess": "Sync request successfully sent!",
        "forceSyncError": "Sync request failed!",
        "pmsGetVendorError": "Could not get the {pms} vendor list!",
        "ip": "IP",
        "port": "Port",
        "useChecksum": "Use Checksum",
        "pmsVendor": "PMS vendor|PMS Vendors",
        "defaultTimeoutSeconds": "Default message timeout (seconds)",
        "charSet": "Character set"
    },
    "cast": {
        "config": {
            "adminConfigHeader": "Chromecast Management System configuration",
            "authToken": "Authentication Token",
            "authTokenHint": "Security token for the chromecast management system.",
            "networks": {
                "createNew": "Create New",
                "addNetworkWithType": "Add {type} network",
                "addChromecastNetwork": "Add Chrom",
                "useExisting": "Use Existing",
                "interfaceName": "Interface Name",
                "parentInterface": "Parent Interface",
                "networkPrefixLength": "Network Prefix Length",
                "vlanTag": "VLAN Tag",
                "chromecastNetworks": "Chromecast Networks",
                "clientNetworks": "Client Networks",
                "proxyIp": "Proxy IP",
                "vlanId": "VlanId",
                "interface": "Interface",
                "parentIface": "Parent Iface",
                "ipAddressHint": "Enter the IP address for this interface. This will be the address where devices on this network can reach this server.",
                "ipPlaceholder": "192.168.0.10",
                "networkPrefixPlaceholder": "24",
                "vlanTagPlaceholder": "10",
                "startMdnsSending": "Start mDNS sending",
                "stopMdnsSending": "Stop mDNS sending",
            },
            "hostPlaceholder": "0.0.0.0",
            "networkProxyHost": "Network Proxy Host",
            "networkProxyHostHint": "The Host address to set up the connection between portal and Chromecast Management system.",
            "proxy": "Proxy",
            "chromecastProxy": "Chromecast Proxy Configuration",
            "chromecastNetworkType": "Chromecast network",
            "chromecastProxyServerNetworkConfig": "Chromecast Proxy Server Network Configuration",
            "chromecastProxyServerStatus": "Chromecast Proxy Server Status",
            "networkInterfaceStatus": "Network Interface Status",
            "chromecastProxyServerSignageStatus": "Chromecast Proxy Server Signage Status",
            "proxyIsInternetGateway": "Proxy is internet gateway",
            "internetInterface": "Internet Interface",
            "isGatewayForChromecasts": "Is gateway for Chromecasts",
            "isDHCPForChromecasts": "Is DHCP for Chromecasts",
            "isGatewayForClients": "Is gateway for Clients",
            "isDHCPForClients": "Is DHCP for Clients",
            "chromecastProxyServerForcedReturnPath": "Chromecast Proxy Server Forced Return Path",
            "webInterfaceForceReturnPath": "Web Interface force return path",
            "webInterfaceForceReturnPathHint": "Leave blank unless there is multiple client networks. This is used to tell which interface the server should return the answer for any request from the clients.",
            "forcedReturnPathGateway": "Forced return path gateway",
            "forcedReturnPathGatewayPlaceholder": "Default gateway",
            "forcedReturnPathGatewayHint": "The gateway connected to the forced return path network. Leave blank to use the Chromecast server's default gateway",
            "advancedNetworking": "Advanced Networking",
            "proxyServerConfig": "Proxy Server Configuration",
            "chromecastConfig": "Chromecast Configuration",
            "pairingCodeConfig": "Pairing Code Configuration",
            "clientRegistrationHost": "Client registration host",
            "clientRegistrationHostHint": "Enter the domain or IP address that points to this server on the client network. Do not add http:// or any URL scheme.",
            "clientRegistrationHostPlaceholder": "cast.example.com",
            "guestWifiName": "Guest WiFi name",
            "guestWifiNameHint": "WiFi name to be shown on screen when using standalone mode. See documentation on customization for more information.",
            "guestWiFiPassword": "Guest WiFi password",
            "guestWiFiPasswordHint": "WiFi passwword to be shown on screen when using standalone mode. See documentation on customization for more information.",
            "enableSignageIntegration": "Enable Signage integration",
            "globalChromecastName": "Global Chromecast Name",
            "globalChromecastNameHint": "Chromecast name which should be shown for all the guests.",
            "globalChromecastNamePlaceholder": "In-Room Chromecast",
            "timeZone": "Time Zone",
            "timeZoneHint": "This is the time zone for all the connected Chromecasts.",
            "locale": "Locale",
            "localeHint": "This is  the display language of all connected Chromecasts.",
            "pairingCodeSet": "Pairing code set",
            "pairingCodeSetHint": "Character set for generating pairing code. Characters entered here will be the only ones to be used to generate the code.",
            "pairingCodeIsCaseSensitive": "Pairing code is case sensitive",
            "pairingCodeLength": "Pairing code length",
            "pairingCodeTimeoutMinutes": "Pairing code time-out minutes",
            "pairingCodeTimeoutMinutesHint": "How long each code should be valid before displaying a new code. When used, a new code is displayed regardless. (Default 10 minutes)",
            "pairTimeoutHours": "pair time-out hours",
            "pairTimeoutHoursHint": "How long a paired guest should be paired before automatically being disconnected. (Default 5 hours). To use a set time per day, use the checkbox below and configure each day separately.",
            "useCustomTimeoutTimePerDayInstead": "Use custom time-out time per day instead",
            "networkProxySaveError": "Error saving Network Proxy Config",
            "saveError": "Error saving configuration",
            "saveSuccess": "Configuration saved",
            "connectionSuccess": "Connection established",
            "connectionFailure": "Could not establish connection",
            "interfaceFetchFailure": "Could not retrieve Interfaces",
            "reloadTooltipDefault": "Fetches saved data",
            "resetTooltip": "Resets the current configuration by fetching a saved one.",
            "routingOfClientChromecastSessions": "Support routing of client Chromecast sessions",
            "routingOfClientNetworkMulticast": "Support routing of client network multicast",
            "supportNetflix": "Support Netflix on Gen4 (AndroidTV) casts",
            "failedToLoadMsg": "Configuration failed to load"
        },
        "eventLogs": {
            "typeDescription": "Event Log",
            "time": "Time",
            "eventName": "Event Name",
            "castUdn": "Cast UDN",
            "castName": "Cast Name",
            "clientVid": "Client VID",
            "additionalData": "Additional Data"
        },
        "response": {
            "chromecastFetchFailed": "Could not fetch Chromecasts.",
            "unregisteredCastFetchFailed": "Could not fetch unregistered Chromecasts",
            "activeSessionFetchFailed": "Could not fetch active sessions",
            "statusFetchFailed": "Could not fetch Chromecast status",
            "statisticsFetchFailed": "Could not fetch statistics",
            "eventLogFetchFailed": "Could not fetch event log",
            "chromecastProxyConfigFetchFailed": "Could not fetch Chromecast Proxy Configuration",
            "networksFetchFailed": "Could not fetch networks",
            "advancedNetworkingFetchFailed": "Could not fetch advanced networking configuration",
        },
        "status": {
            "reassociationBtnText": "Reassociation to DS",
            "reassociationTitle": "Reassociate to Signage?",
            "reassociationWarning": "The current association with Digital Signage will be lost This should only be used in cases where Cast is unable to register with Digital Signage or the previously associated screen in Digital Signage has been deleted. \n Create a new association?",
            "reassociationMsg": "Create a new association?",
            "reassociationSuccess": "Reassociated to DS",
            "reassociationFailed": "Failed to reassociate to DS",

            "version": "Version",
            "serverId": "Server ID",
            "licensedFor": "Licensed for",
            "status": "Status",
            "eno1": "eno1",
            "wip58s0": "wip58s0",
            "clientId": "Client ID",
            "clientToken": "Client Token",
            "installerToken": "Installer Token",
            "installerCode": "Installer Code",
            "connected": "Connected to Push Server",
            "authenticated": "Authenticated"
        },
        "chromecasts": {
            "info": {
                "castConfig": "Chromecast Configuration",
                "deviceAlias": "Device Alias",
                "deviceName": "Device Name",
                "uptime": "Uptime",
                "isConfigurable": "Is configurable",
                "wifiMac": "WiFi MAC",
                "ssid": "SSID"
            },
            "refreshStatusBtn": "Refresh Status",
            "refreshStatusSuccess": "Chromecast data is being refreshed.\n" +
                    "This might take a minute depending on how many Chromecasts you have, press 'Reload' to see the updated data",
            "refreshStatusFailed": "Failed to refresh status.",
            "saveSuccess": "Updating Chromecast successful",
            "saveFailed": "Updating Chromecast failed",
            "unregister": "Unregister",
            "showConfig": "Show Config",
            "unregisterConfirmTitle": "Unregister Chromecast?",
            "unregisterConfirmMsg": "Are you sure you want to unregister {cast}",
            "unregisterSuccess": "Unregistered {cast}",
            "unregisterFailed": "Unregistering {cast} failed.",
            "updateStatusSuccess": "Updated status for {number} Chromecast | Updated status for {number} Chromecasts.",
            "updateStatusFailed": "Updating Status failed.",
            "showNameSuccess": "Showing name for {number} Chromecast | Showing names for {number} Chromecasts.",
            "showNameFailed": "Failed to show name.",
            "rebootSuccess": "Rebooted {number} Chromecast | Rebooted {number} Chromecasts.",
            "rebootFailed": "Failed to reboot Chromecasts.",
            "deletePairingsConfirmTitle": "Delete pairings",
            "deletePairingsConfirmMsg": "Delete pairings for room?",
            "deletePairingsSuccess": "Deleted pairings for {number} room | Deleted pairings for {number} rooms.",
            "deletePairingsFailed": "Failed to delete pairings for room {room}.",
            "forceStartCastingFailed": "Failed to force-start casting for cast {cast}.",
            "chromecastPairingCode": "Chromecast Pairing Code"
        },
        "unregisteredCasts": {
            "title": "Unregistered Cast",
            "register": "Register",
            "registerChromecast": "Register Chromecast",
            "registerSuccess": "Registered Chromecast {cast} on room {room}",
            "genericFailure": "Could not register Chromecast",
            "limitReachedFailure": "Chromecast registration limit reached",
            "deleteCastConfirmTitle": "Delete Chromecast",
            "deleteCastConfirmMsg": "Are you sure you wish to delete this Chromecast?",
            "deleteCastFailed": "Failed to delete Chromecast",
            "deleteCastSuccess": "Chromecast deleted"
        },
        "statistics": {
            "fetch": "Fetch",
            "usageInHours": "Cast Usage in Hours",
            "noOfPairingsPerDay": "Number of Pairings Per Day",
            "appUsageInHours": "App Usage in Hours",
            "consecutiveHourFilterLabel": "Filter events",
            "consecutiveHourFilterHint": "Filters out events with more hours than the set amount.",
            "downloadVisiblePeriod": "Download visible period",
            "exportPairings": "Export Pairings",
            "exportAppUsage": "Export App Usage"
        },
        "activeSessions": {
            "title": "Active Session",
            "pinOrUnpin": "Pin or unpin this pairing",
            "pinned": "Pinned",
            "pinSuccess": "Client pinned",
            "unpinSuccess": "Client unpinned",
            "multiplePinUnpinSuccess": "{clients} pinned/unpinned",
            "pinFailed": "Could not pin client",
            "disconnect": "Disconnect Client",
            "disconnectConfirmTitle": "Disconnect client",
            "disconnectConfirmMsg": "Disconnect client from Chromecast?",
            "disconnectSuccess": "Disconnected Client",
            "disconnectFailed": "Could not disconnect client"

        },
        "type": "Chromecast | Chromecasts",
        "runningApp": "Running App",
        "available": "Available",
        "connection": "Conn.",
        "mac": "MAC",
        "macAddress": "MAC Address",
        "paired": "Paired",
        "udn": "UDN",
        "signalNoiseLevel": "S/N",
        "client": "Cli",
        "configurable": "Configurable",
        "connected": "Connected",
        "createdAt": "Created At",
        "actions": {
            "updateStatus": "Update status",
            "deletePairings": "Delete pairings",
            "showName": "Show name",
            "stopCurrentSession": "Reboot",
            "generatePairingCode": "Generate pairing code",
            "exportSelectedToCsv": "Export selected to CSV",
            "forceStartCasting": "Force start"
        },
        "networkProxySaveConfirmationMsg": "Are you sure you wish to save? \n " +
                "If config is set incorrectly, chromecast functionality will break.",
        "castServiceOfflineMsg": "The Chromecast service is currently unavailable, possibly due to the server being offline or configuration error in Administration/Chromecast configuration settings."
    },
    "streamManagement": {
        "allocatedStreams": {
            "device": "Device | Devices",
            "allocatedStream": "Allocated stream",
            "itemType": "Device",
            "addDevice": "Add Device",
            "editDevice": "Edit Device",
            "deallocateStreamTitle": "Deallocate stream?",
            "deallocateStreamConfirmMsg": "Are you sure you wish to deallocate stream?",
            "editAllocatedStream": "Edit Allocated Stream",
            "mediaUrl": "Media Url",
            "streamAllocatorConfig": "Stream Allocator Configuration",
            "deallocate": "Deallocate",
            "deleteDeviceMsg": "WARNING: Deleting devices will immediately end the session for any allocated TVs",
            "deleteDeviceConfirm": "Are you sure you wish to delete device?",
            "chromecastIntegrationDescription": "Select to enable Chromecast integration"
        },
        "config": {
            "minTimeoutWarn": "Minimum timeout is 2 minutes"
        },
        "allocationLogEntries": {
            "eventType": {
                "allocationFailure": "Failed to allocate stream"
            },
            "eventReason": {
                 "noAvailableDevices": "No available devices"
            },
            "title": "Stream Allocation Log",
            "itemType": "Stream Allocation Log Entry"
        }
    },
    "jobs": {
        "jobList": {
            "title": "Notifications"
        },
        "startMsgs": {
            "imageUpload": "Upload of image {title} started",
            "fontUpload": "Upload of font {title} started",
            "themeImport": "Import of theme {title} started",
            "logoUpload": "Upload of logo {title} started"
        },
        "successMsgs": {
            "imageUpload": "Upload of image {title} successful",
            "fontUpload": "Upload of font {title} successful",
            "logoUpload": "Upload of logo {title} successful"
        },
        "failedMsgs": {
            "imageUpload": "Upload of image {title} failed",
            "fontUpload": "Upload of font {title} failed",
            "logoUpload": "Upload of logo {title} failed"
        },

    },
    "content": {
        "config": {
            "resourceserver": "Resource Server",
            "resourcesyncservice": "Resource Sync Service",
            "networkproxy": "Network Proxy",
            "resourceServerPort": "Resource Server Port",
            "resourceServerPortHint": "Enter the port for the resource server",
            "resourceServerFileLocation": "Resource Server Internal URL",
            "resourceServerFileLocationHint": "Internal Url for use with TV",
            "resourceSyncServiceFileLocation": "Resource Sync Service File Location",
            "resourceSyncServiceFileLocationHint": "Enter the file location where all files should be stored for the resource sync service",
            "enableChangingURL": "Manual override the internal URL",
            "selectNic": "Network Interface",
            "saveConfig": "Save configuration",
            "saveConfigWarn": "Are you sure you wish to save? If configuration is set incorrectly, serving functionality will break.",
            "saveSuccess": "Configuration saved",
            "updateSelectedNic": "Update selected network interface",
            "confirmUpdateSelectedNic": "Warning: Changing the selected network interface will update the internal url for the resource server configuration. Are you sure you wish to continue?",
            "configSaveError": "Error saving configuration",
            "rangeError": "Only ports between 1024 and 49151 are allowed",
            "checkConfig": "Check configuration",
            "configurationOK": "Configuration OK",
            "errorWithConfigurationPort": "Error with configuration port",
            "errorWithConfigurationIp": "IP does not match any network interface",
            "errorWithConfigurationPortAndIp": "Error with configuration IP and port",
            "errorWithConfiguration": "Error with configuration - missing internal URL",
            "missingConfig": "Press RELOAD to fetch configuration",
            "changeURLWarn": "Warning: If URL is set incorrectly, serving functionality will break.",
            "tables": "Tables",
            "cellMaxLength": "Cell Max Text Length",
            "cellMaxLengthHint": "Enter the value for the table cell max text length."
        }
    }
}
