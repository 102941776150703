import auth from './auth';
import context from './context';
import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import BaseContextModule from "@/store/modules/contexts/BaseContextModule";
import BaseServiceConfigModule from '@/store/modules/base/BaseServiceConfigModule';
import pushChannel from './pushchannel/messages';
import locationModule from './location/locations';
import guests from "@/store/modules/hospitality/guests";
import roomAccounts from "@/store/modules/hospitality/roomAccounts";
import hospitalityLocations from "@/store/modules/hospitality/hospitalityLocations";
import wakeups from "@/store/modules/hospitality/wakeups";
import products from "@/store/modules/licenseManagement/products";
import interactiveMenuPages from "@/store/modules/content/interactiveMenuPages";
import compositeMenuPages from "@/store/modules/content/compositeMenuPages";
import templates from "@/store/modules/content/templates";
import templateSources from "@/store/modules/content/templateSources";
import mediaChannels from "@/store/modules/content/mediaChannels";
import documents from "@/store/modules/content/documents";
import licenses from "@/store/modules/admin/licenses";
import areas from "@/store/modules/admin/areas";
import contextAccess from "@/store/modules/admin/contextAccess";
import overviewContexts from "@/store/modules/overview/contexts";
import clients from "@/store/modules/clients/clients";
import currentVendorConfiguration from "@/store/modules/contextManagement/currentVendorConfiguration";
import languageInfo from "@/store/modules/contextManagement/languageInfo";
import screens from "@/store/modules/screens/screens";
import dashboard from "./dashboard";
import screenPreview from "@/store/modules/screens/screenPreview";
import userAccount from "./userAccount";
import contextTypes from "@/store/modules/admin/contextTypes";
import systemContextConfigs from "@/store/modules/admin/systemContextConfigs";
import guestMessages from "@/store/modules/hospitality/guestMessages";
import signageHeaders from "@/store/modules/screens/signageHeaders";
import homeSiteHeaders from "@/store/modules/overview/homeSiteHeaders";
import homeSiteHeadersPPDS from "@/store/modules/overview/homeSiteHeadersPPDS";
import tvHeaders from "@/store/modules/screens/tvHeaders";
import contentSchedules from "@/store/modules/signage/contentSchedules";
import identityProviders from "@/store/modules/identityProviders";
import virtualRemoteClients from "@/store/modules/hospitality/virtualRemoteClients";
import streamDevices from "@/store/modules/streamDevices";
import industries from "@/store/modules/contexts/industries";
import registeredCasts from "@/store/modules/cast/registeredCasts";
import allocationLogEntries from "@/store/modules/streamallocator/allocationLogEntries";
import playlists from "@/store/modules//content/playlists";
import BaseDomainModule from "@/store/modules/domains/BaseDomainModule";
import eventLocations from "@/store/modules/events/eventLocations";
import duplicateRegisteredClients from "@/store/modules/clients/duplicateRegisteredClients";

export default function (apis) {
    return {
        auth: auth,
        context: context,
        licenses: licenses,
        locations: locationModule,
        locationCategories: new BaseCrudModule(apis.locationCategories),
        locationTags: new BaseCrudModule(apis.locationTags),
        wakeups: wakeups,
        guestMessages: guestMessages,
        guestMessageTemplates: new BaseCrudModule(apis.guestMessageTemplates),
        screenPreview: screenPreview,
        features: new BaseCrudModule(apis.features),
        products: products,
        systemContextConfigs: systemContextConfigs,
        themes: new BaseContextModule(apis.themes),
        deployments: new BaseContextModule(apis.deployments),
        legacies: new BaseContextModule(apis.legacies),
        resellers: new BaseContextModule(apis.resellers),
        distributors: new BaseContextModule(apis.distributors),
        systems: new BaseContextModule(apis.systems),
        sharingContexts: new BaseContextModule(apis.sharingContexts),
        contentProviderContexts: new BaseContextModule(apis.contentProviderContexts),
        domains: new BaseDomainModule(apis.domains),
        contextTypes: contextTypes,
        accounts: new BaseCrudModule(apis.accounts),
        apiAccounts: new BaseCrudModule(apis.apiAccounts),
        accountAccesses: new BaseCrudModule(apis.accountAccesses),
        contextAccountsDirect: new BaseCrudModule(apis.contextAccountsDirect),
        contextAccess: contextAccess,
        areas: areas,
        owners: new BaseCrudModule(apis.owners,'', null, 'owners'),
        guests: guests,
        roomAccounts: roomAccounts,
        events: new BaseCrudModule(apis.events),
        integrationLocations: new BaseCrudModule(apis.integrationLocations),
        externalLocations: new BaseCrudModule(apis.externalLocations),
        eventIntegrations: new BaseCrudModule(apis.eventIntegrations),
        eventIntegrationConfigurations: new BaseCrudModule(apis.eventIntegrationConfigurations),
        hospitalityLocations: hospitalityLocations,
        hospitalityServiceConfig: new BaseServiceConfigModule(apis.hospitalityServiceConfig),
        screenServiceConfig: new BaseServiceConfigModule(apis.screenServiceConfig),
        signageServiceConfig: new BaseServiceConfigModule(apis.signageServiceConfig),
        clientServiceConfig: new BaseServiceConfigModule(apis.clientServiceConfig),
        eventServiceConfig: new BaseServiceConfigModule(apis.eventServiceConfig),
        contextManagementServiceConfig: new BaseServiceConfigModule(apis.contextManagementServiceConfig),
        pmsServiceConfig: new BaseServiceConfigModule(apis.pmsServiceConfig),
        pmsLogEntries: new BaseCrudModule(apis.pmsLogEntries),
        interactiveMenuPages: interactiveMenuPages,
        compositeMenuPages: compositeMenuPages,
        mediaChannels: mediaChannels,
        globalChannels: new BaseCrudModule(apis.globalChannels),
        channelTables: new BaseCrudModule(apis.channelTables),
        configSchedules: new BaseCrudModule(apis.configSchedules),
        schedulePlans: new BaseCrudModule(apis.schedulePlans),
        contentSchedules: contentSchedules,
        screenSetups: new BaseCrudModule(apis.screenSetups),
        images: new BaseCrudModule(apis.images),
        fonts: new BaseCrudModule(apis.fonts),
        videos: new BaseCrudModule(apis.videos),
        logos: new BaseCrudModule(apis.logos),
        logoFolders: new BaseCrudModule(apis.logoFolders),
        clients: clients,
        clientStatuses: new BaseCrudModule(apis.clientStatuses),
        templates: templates,
        templateSources: templateSources,
        timeZones: new BaseCrudModule(apis.timeZones),
        installCodes: new BaseCrudModule(apis.installCodes),
        documents: documents,
        tables: new BaseCrudModule(apis.tables),
        tableSchemas: new BaseCrudModule(apis.tableSchemas),
        tableItems: new BaseCrudModule(apis.tableItems),
        tablesConfigurations: new BaseServiceConfigModule(apis.tablesConfigurations),
        resourceFolders: new BaseCrudModule(apis.resourceFolders),
        webRadios: new BaseCrudModule(apis.webRadios),
        documentFolders: new BaseCrudModule(apis.documentFolders),
        interactiveMenuPageFolders: new BaseCrudModule(apis.interactiveMenuPageFolders),
        compositeMenuPageFolders: new BaseCrudModule(apis.compositeMenuPageFolders),
        templateFolders: new BaseCrudModule(apis.templateFolders),
        roles: new BaseCrudModule(apis.roles),
        privilegeSets: new BaseCrudModule(apis.privilegeSets),
        systemConfig: new BaseCrudModule(apis.systemConfig),
        pushChannel: pushChannel,
        screens: screens,
        playlists: playlists,
        playlistFolders: new BaseCrudModule(apis.playlistFolders),
        languageInfo: languageInfo,
        scheduleRuleSets: new BaseCrudModule(apis.scheduleRuleSets),
        dashboard: dashboard,
        overviewContexts: overviewContexts,
        currentVendorConfiguration: currentVendorConfiguration,
        userAccount: userAccount,
        shares: new BaseCrudModule(apis.shares),
        snapshots: new BaseCrudModule(apis.snapshots),
        sharedContexts: new BaseCrudModule(apis.sharedContexts),
        menuLists: new BaseCrudModule(apis.menuLists),
        pmsStatus: new BaseCrudModule(apis.pmsStatus),
        networkProxies: new BaseCrudModule(apis.networkProxies),
        resourceServerConfigurations: new BaseServiceConfigModule(apis.resourceServerConfigurations),
        signageHeaders: signageHeaders,
        homeSiteHeaders: homeSiteHeaders,
        homeSiteHeadersPPDS: homeSiteHeadersPPDS,
        tvHeaders: tvHeaders,
        currentScreenContents: new BaseCrudModule(apis.currentScreenContents),
        identityProviders: identityProviders,
        virtualRemoteClients: virtualRemoteClients,
        industries: industries,
        // Chromecast stuff
        registeredCasts: registeredCasts,
        unregisteredCasts: new BaseCrudModule(apis.unregisteredCasts),
        activeCastSessions: new BaseCrudModule(apis.activeCastSessions),
        castEventLogs: new BaseCrudModule(apis.castEventLogs),
        chromecastProxyServerStatus: new BaseCrudModule(apis.chromecastProxyServerStatus),
        chromecastProxyServerSignageStatus: new BaseCrudModule(apis.chromecastProxyServerSignageStatus),
        clientNetworks: new BaseCrudModule(apis.clientNetworks),
        chromecastNetworks: new BaseCrudModule(apis.chromecastNetworks),
        screenContents: new BaseCrudModule(apis.screenContents),
        castStatistics: new BaseCrudModule(apis.castStatistics),
        castProxyConfiguration: new BaseServiceConfigModule(apis.castProxyConfiguration),
        networkProxyConfigurations: new BaseServiceConfigModule(apis.networkProxyConfigurations),
        advancedNetworkConfigurations: new BaseServiceConfigModule(apis.advancedNetworkConfigurations),
        //Streams
        allocatedStreams: new BaseCrudModule(apis.allocatedStreams),
        streamDevices: streamDevices,
        streamAllocatorServiceConfig: new BaseServiceConfigModule(apis.streamAllocatorServiceConfig),
        allocationLogEntries: allocationLogEntries,
        deletedContexts: new BaseCrudModule(apis.deletedContexts),
        duplicateRegisteredClients: duplicateRegisteredClients,
        jobs: new BaseCrudModule(apis.jobs),
        screenWayfindingConfigurations: new BaseCrudModule(apis.screenWayfindingConfigurations),
        eventLocations: eventLocations,
        wayfindingIcons: new BaseCrudModule(apis.wayfindingIcons),
        wayfindingIconFolders: new BaseCrudModule(apis.wayfindingIconFolders),
        flightLogos: new BaseCrudModule(apis.flightLogos),
        auditLogs: new BaseCrudModule(apis.auditLogs),
        auditBlacklist: new BaseCrudModule(apis.auditBlacklist),
        contentProviderProperties: new BaseCrudModule(apis.contentProviderProperties),
    }
};
